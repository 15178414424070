import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchStudent from "../../../assets/img/search.svg";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import AddCircle from "../../../assets/img/add_circle.svg";
import StudentCard from "./studentCard";
import Drawer from "@mui/material/Drawer";
import Filter from "./filter";
import { useSelector, useDispatch } from "react-redux";
import { HANDLE_STUDENT_SEARCH, RESET_STUDENT_SEARCH } from "../../../actions/types";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import MoreFilter from "./moreFilter";
import Empty from "../../../assets/img/empty_student.svg";
import { useMediaQuery } from "@mui/material";
import { roleConstant } from "../../../utils/helperFunctions";
import { segmentEvents } from "../../../utils/constants";
import AdvancePaymentModal from "../../lead/leadList/paymentModal";
import OtpVerificationModal from "../../lead/leadList/OtpVerificationModal";
import ToastMessage from "../../common/toastMessage";
import { toast } from "react-toastify";
import { message } from "antd";

const openInNewTab = (href) => {
	Object.assign(document.createElement("a"), {
		target: "_blank",
		rel: "noopener noreferrer",
		href: href,
	}).click();
};

const StudentSearch = ({ isLeadSearch }) => {
	const { roleList } = useSelector((state) => state.auth);

	const [isStudentManager, setIsStudentManager] = useState(false);
	const [selectedLead, setSelectedLead] = useState(null);
	const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
	const [otp, setOtp] = useState("");
	const [otpError, setOtpError] = useState(null);
	const [hasCentre, setHasCentre] = useState(false);
	const [advancePaymentModalOpen, setAdvancePaymentModalOpen] = useState(false);
	const [sendingOtp, setSendingOtp] = useState(false);
	const [openModal, toggleModal] = useState(false);
	const [verifying, setVerifying] = useState(false);

	const closeModal = () => {
		toggleModal(false);
	};

	const closeAdvancePaymentModal = () => {
		setAdvancePaymentModalOpen(false);
	};

	const sendOtp = async (id, retry) => {
		setSendingOtp(true);
		message.config({
			top: 100,
			duration: 2,
			maxCount: 1,
		});
		message.loading("Please wait generating OTP...", 0);
		const status = await Api.doFetch("POST", {}, `${ApiUrl.LEAD_VERIFY_MOBILE}/${id}`).then((res) => {
			setOtp("");
			setSendingOtp(false);
			!sendingOtp && message.destroy();
			if (res.status) {
				toggleModal(true);
				toast(<ToastMessage type="success" text={retry ? "OTP resent successfully" : "OTP sent successfully"} />);
				return true;
			} else {
				toast(<ToastMessage type="error" text="Mobile number verification failed" />);
				return false;
			}
		});
		return status;
	};

	const verifyOtp = async () => {
		let copyList = studentList;
		let index = studentList.findIndex((s) => s.id === selectedLead);
		setVerifying(true);
		await Api.doFetch(
			"POST",
			{
				otp: otp,
			},
			`${ApiUrl.LEAD_VERIFY_OTP}/${selectedLead}`
		).then((res) => {
			setVerifying(false);
			if (res.status) {
				if (index !== -1) {
					copyList[index].is_mobile = true;
					dispatch({
						type: HANDLE_STUDENT_SEARCH,
						payload: {
							studentList: copyList,
						},
					});
				}
				closeModal();

				toast(<ToastMessage type="success" text="OTP verified successfully" />);
				setTimeout(() => {
					setAdvancePaymentModalOpen(true);
				}, 1000);
			} else {
				toast(<ToastMessage type="error" text="Mobile number verification failed" />);
				setOtpError("Invalid OTP, please try again");
			}
		});
	};

	useEffect(() => {
		if (roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
			if (roleList.findIndex((x) => x.role_name === roleConstant.COUNSELOR) === -1) {
				setIsStudentManager(true);
			}
		}
	}, [roleList]);

	const hasSearchedRef = useRef(false);

	const dispatch = useDispatch();

	const {
		searchFilterApplied,
		advancedFilterApplied,
		studentList,
		studentData,
		selectedCentre,
		selectedCourse,
		selectedBatch,
		selectedState,
		selectedCity,
		skip,
		limit,
		continueScroll,
	} = useSelector((state) => state.studentSearch);
	const isMobile = useMediaQuery("(max-width:600px)");

	const navigate = useNavigate();

	const [searchLoading, setSearchLoading] = useState(false);
	const [advancedLoading, setAdvancedLoading] = useState(false);
	const [scrollLoading, setScrollLoading] = useState(false);
	const [openFilter, setOpenFilter] = useState(false);
	const [tempSearchText, setTempSearchText] = useState("");

	const onChangeStudentSearch = (e) => {
		setTempSearchText(e.target.value);
	};

	const advancedFilterHasValues = () => {
		return selectedCentre || selectedCourse || selectedBatch || selectedState || selectedCity ? true : false;
	};

	const [cityList, setCityList] = useState([]);

	const getCityList = async () => {
		const data = await Api.doFetch("GET", {}, ApiUrl.CITY_LIST);

		if (data && data.status && data.payload && data.payload.city_list && data.payload.city_list.length > 0) {
			setCityList(data.payload.city_list);
		} else {
			setCityList([]);
		}
	};

	const getStudent = async (canSearch = true, skipToStart) => {
		if (+skip === 0 || skipToStart) {
			let url = `${
				ApiUrl.GET_STUDENT_LIST_V1
			}?search_filter=${tempSearchText}&school_id=${selectedCentre}&class=${selectedCourse}&section=${selectedBatch}&city=${selectedCity}&state=${selectedState}&skip=${0}&limit=${limit}`;
			if (tempSearchText && canSearch) {
				// url = `${ApiUrl.GET_STUDENT_LIST}?search_filter=${searchText}`;
				if (!advancedFilterHasValues()) {
					setSearchLoading(true);
				} else {
					setAdvancedLoading(true);
				}
			} else {
				url = `${
					ApiUrl.GET_STUDENT_LIST_V1
				}?search_filter=&school_id=${selectedCentre}&class=${selectedCourse}&section=${selectedBatch}&city=${selectedCity}&state=${selectedState}&skip=${0}&limit=${limit}`;
				setAdvancedLoading(true);
			}

			const data = await Api.doFetch("GET", {}, url);

			setSearchLoading(false);
			setAdvancedLoading(false);

			let element = document.getElementById("student_search_scroll_container");
			element.scrollTop = 0;

			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					searchFilterApplied: tempSearchText || !canSearch ? true : false,
					advancedFilterApplied: advancedFilterHasValues(),
					studentData: data && data.length > 0 ? data : [],
					studentList: data && data.length > 0 ? data : [],
					continueScroll: data && +data.length === +limit,
				},
			});
		} else {
			let url = `${ApiUrl.GET_STUDENT_LIST_V1}?search_filter=${
				canSearch ? tempSearchText : ""
			}&school_id=${selectedCentre}&class=${selectedCourse}&section=${selectedBatch}&city=${selectedCity}&state=${selectedState}&skip=${skip}&limit=${limit}`;

			setScrollLoading(true);
			const data = await Api.doFetch("GET", {}, url);
			setScrollLoading(false);
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					searchFilterApplied: tempSearchText ? true : false,
					advancedFilterApplied: advancedFilterHasValues(),
					studentData: data && data.length > 0 ? [...studentData, ...data] : studentData,
					studentList: data && data.length > 0 ? [...studentList, ...data] : studentList,
					continueScroll: data && +data.length === +limit,
				},
			});
		}
	};

	const handleScroll = async (event) => {
		const e = event.target;
		let threshold = e.scrollHeight - e.scrollTop <= e.clientHeight + 150;

		if (threshold && continueScroll) {
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					skip: skip + limit,
					continueScroll: false,
				},
			});
		}
	};

	const resetSearchRef = () => {
		setTempSearchText("");
		dispatch({ type: RESET_STUDENT_SEARCH });
		hasSearchedRef.current = false;
	};

	useEffect(() => {
		if (advancedFilterHasValues()) getStudent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCentre, selectedCourse, selectedBatch, selectedState, selectedCity]);

	useEffect(() => {
		if (skip > 0) {
			getStudent();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skip, limit]);

	useEffect(() => {
		if (!isLeadSearch) getCityList();
		return () => dispatch({ type: RESET_STUDENT_SEARCH });
	}, []);

	const onSearch = () => {
		if (searchLoading) return;
		if (advancedFilterHasValues() || !!tempSearchText.trim() || (tempSearchText === "" && hasSearchedRef.current)) {
			getStudent(true, true);
			hasSearchedRef.current = true;
		}
		if (tempSearchText.trim()) {
			Api.trackEvent(segmentEvents.STUDENT.CLICKED_SEARCH_STUDENT);
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					skip: 0,
					continueScroll: false,
					searchText: tempSearchText,
				},
			});
		}
	};

	return (
		<div className="containerMain">
			<AdvancePaymentModal
				leadID={selectedLead}
				hasCentre={hasCentre}
				advancePaymentModalOpen={advancePaymentModalOpen}
				closeAdvancePaymentModal={closeAdvancePaymentModal}
			/>
			{openModal && (
				<OtpVerificationModal
					openModal={openModal}
					closeModal={closeModal}
					mobile={selectedMobileNumber}
					otp={otp}
					setOtp={setOtp}
					verifyOtp={verifyOtp}
					verifying={verifying}
					sendOtp={sendOtp}
					otpError={otpError}
					setOtpError={setOtpError}
					// nextStep={() => setStep(2)}
				/>
			)}
			<Drawer
				anchor={"right"}
				open={openFilter}
				onClose={() => {
					setOpenFilter(false);
				}}
				className="student_search_filter_drawer"
			>
				<Filter close={() => setOpenFilter(false)} tempSearch={tempSearchText} allCity={cityList} />
			</Drawer>

			<div
				className={`student_search_container mb-5 ${searchFilterApplied || advancedFilterApplied ? "student_search_container_filter" : ""}${
					isMobile ? " is-mobile" : ""
				}`}
			>
				<div className="d-flex student_search_container-input align-items-center justify-content-between">
					<img src={SearchStudent} alt="search" className="student_search_image" />
					<input
						className="student_search_input"
						value={tempSearchText}
						onChange={(e) => {
							onChangeStudentSearch(e);
							if (!e.target.value) {
								if (advancedFilterHasValues()) {
									dispatch({
										type: HANDLE_STUDENT_SEARCH,
										payload: {
											searchText: "",
											skip: 0,
											limit: 10,
											continueScroll: false,
										},
									});
									// getStudent(false);
								} else {
									resetSearchRef();
								}
							}
						}}
						onKeyDown={(e) => {
							if (e.keyCode === 13) {
								onSearch();
							}
						}}
						placeholder={isLeadSearch ? "Search by student name/ mobile number" : "Search by Student ID / email / name"}
						disabled={searchLoading ? true : false}
					/>

					<button
						className={`btn btn-md student_search_button ${!tempSearchText.trim() ? "student_search_button_disabled" : ""}`}
						onClick={onSearch}
					>
						Search
						{searchLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
					</button>
				</div>

				{!isLeadSearch ? (
					<div className="student_search_more_filter_container">
						<span
							className="student_search_more_filter"
							onClick={() => {
								setOpenFilter(true);
								Api.trackEvent(segmentEvents.STUDENT.CLICKED_STUDENT_SEARCH_MORE_FILTER);
							}}
						>
							More Filters
						</span>
					</div>
				) : (
					<div
						className="student_search_more_filter_container d-flex justify-content-flex-end"
						onClick={() => {
							dispatch({ type: RESET_STUDENT_SEARCH });
							navigate("/lead/list");
						}}
					>
						<span className="student_search_more_filter">View all leads</span>
					</div>
				)}
			</div>

			{advancedFilterHasValues() && <MoreFilter resetSearchRef={resetSearchRef} />}

			<div
				className={`student_search_list_container ${searchFilterApplied || advancedFilterApplied ? "student_search_list_container_filter" : ""}${
					isMobile ? " is-mobile" : ""
				}`}
			>
				<div className="d-flex align-items-center mb-5">
					{!isStudentManager && (
						<img src={LeftArrow} alt="back" className="student_list_back" onClick={() => navigate(isLeadSearch ? "/lead" : "/student")} />
					)}

					<span className="student_search_list_header">List of matching {isLeadSearch ? "leads" : "students"}</span>
				</div>
			</div>

			{!scrollLoading && !searchFilterApplied && !advancedFilterApplied && advancedFilterHasValues() && (
				<div className="mt-5">
					<Loader />
				</div>
			)}

			<div
				id="student_search_scroll_container"
				className={`${advancedFilterHasValues() ? "student_search_scroll_advanced" : "student_search_scroll"} student_search_list_container${
					isMobile ? " is-mobile" : ""
				} ${searchFilterApplied || advancedFilterApplied ? "student_search_list_container_filter" : ""}`}
				onScroll={handleScroll}
			>
				{(searchFilterApplied || advancedFilterApplied) && advancedLoading && !scrollLoading ? (
					<div className="loader_container">
						<Loader />
					</div>
				) : advancedFilterHasValues() && searchLoading ? null : studentList.length === 0 ? (
					<div className="student_search_empty_container">
						<div className="student_search_empty_img_container">
							<img src={Empty} alt="not found" className="student_search_empty_img" />
						</div>

						<p className="student_search_empty_header">No results found</p>
						<span className="student_search_empty_label">Try adjusting your search history to find</span>
						<span className="student_search_empty_label">the best results</span>
					</div>
				) : (
					studentList.map((studentData, index) => {
						return (
							<StudentCard
								studentData={studentData}
								key={index}
								installmentMissed={
									studentData && (studentData.last_installment_due_on || studentData.hostel_last_installment_due_on) ? true : false
								}
								installmentPlanNotCreated={studentData && studentData.is_free ? false : studentData.installment_created ? false : true}
								redirect={() => {
									if (studentData.is_free) openInNewTab(`/student/add?lead-id=${studentData.id}`);
									else openInNewTab(`/student/detail/${studentData.id}`);
								}}
								onEditLead={() => {
									openInNewTab(`/lead/${studentData.id}`);
								}}
								style={index === 0 ? { marginTop: "0rem" } : {}}
								onCollectAdvance={() => {
									setSelectedMobileNumber(studentData?.mobile_number);
									setSelectedLead(studentData.id);
									studentData.centre_id ? setHasCentre(true) : setHasCentre(false);

									if (studentData.is_mobile) {
										setAdvancePaymentModalOpen(true);
									} else {
										sendOtp(studentData?.id);
									}
								}}
							/>
						);
					})
				)}

				{(searchFilterApplied || advancedFilterApplied) && scrollLoading ? (
					<div className="loader_container">
						<Loader />
					</div>
				) : (searchFilterApplied || advancedFilterApplied) && !(searchLoading || scrollLoading || advancedLoading) ? (
					<div
						className="add_student_container"
						onClick={() => {
							navigate(isLeadSearch ? "/lead/add" : "/student/add");
						}}
					>
						<div className="add_student_text_container">
							<img src={AddCircle} alt="add" className="add_student_img" />

							<span className="add_student_container_text">Add New {isLeadSearch ? "Lead" : "Student"}</span>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default StudentSearch;
