export const HANDLE_AUTH_DETAILS = "HANDLE_AUTH_DETAILS";
export const HANDLE_STUDENT_SEARCH = "HANDLE_STUDENT_SEARCH";
export const RESET_STUDENT_SEARCH = "RESET_STUDENT_SEARCH";

export const HANDLE_SUBJECT_DETAILS = "HANDLE_SUBJECT_DETAILS";
export const RESET_SUBJECT_DETAILS = "RESET_SUBJECT_DETAILS";
export const SET_SUBJECT_SCROLL = "SET_SUBJECT_SCROLL";

export const HANDLE_CHAPTER_DETAILS = "HANDLE_CHAPTER_DETAILS";
export const RESET_CHAPTER_DETAILS = "RESET_CHAPTER_DETAILS";
export const SET_CHAPTER_SCROLL = "SET_CHAPTER_SCROLL";

export const HANDLE_HUB_DETAILS = "HANDLE_HUB_DETAILS";
export const RESET_HUB_DETAILS = "RESET_HUB_DETAILS";
export const SET_HUB_SCROLL = "SET_HUB_SCROLL";

export const HANDLE_CENTRE_DETAILS = "HANDLE_CENTRE_DETAILS";
export const RESET_CENTRE_DETAILS = "RESET_CENTRE_DETAILS";
export const SET_CENTRE_SCROLL = "SET_CENTRE_SCROLL";

export const HANDLE_COURSE_DETAILS = "HANDLE_COURSE_DETAILS";
export const RESET_COURSE_DETAILS = "RESET_COURSE_DETAILS";
export const SET_COURSE_SCROLL = "SET_COURSE_SCROLL";

export const HANDLE_BATCH_DETAILS = "HANDLE_BATCH_DETAILS";
export const RESET_BATCH_DETAILS = "RESET_BATCH_DETAILS";
export const SET_BATCH_SCROLL = "SET_BATCH_SCROLL";

export const SCRL_HANDLE_INIT = "SCRL_HANDLE_INIT";
export const SCRL_HANDLE_DETAILS = "SCRL_HANDLE_DETAILS";
export const SCRL_HANDLE_LOADING = "SCRL_HANDLE_LOADING";
export const SCRL_HANDLE_SCROLL_LOADING = "SCRL_HANDLE_SCROLL_LOADING";
export const SCRL_HANDLE_SCROLL_POSITION = "SCRL_HANDLE_SCROLL_POSITION";
export const SCRL_HANDLE_SCROLL_APPEND = "SCRL_HANDLE_SCROLL_APPEND";
export const SCRL_HANDLE_SEARCH_INIT = "SCRL_HANDLE_SEARCH_INIT";
export const SCRL_HANDLE_SEARCH_CHANGE = "SCRL_HANDLE_SEARCH_CHANGE";
export const SCRL_UPDATE_LIST = "SCRL_UPDATE_LIST";
export const SCRL_RESET_LIST = "SCRL_RESET_LIST";
export const SCRL_RESET_SEARCH = "SCRL_RESET_SEARCH";
export const HANDLE_SCRL_LIST = "HANDLE_SCRL_LIST";
export const HANDLE_SCRL_DATA = "HANDLE_SCRL_DATA";
export const RESET = "RESET";

export const HANDLE_COMMON = "HANDLE_COMMON";

export const SCRL_HANDLE_INIT_MULTIPLE = "SCRL_HANDLE_INIT_MULTIPLE";
export const SCRL_HANDLE_DETAILS_MULTIPLE = "SCRL_HANDLE_DETAILS_MULTIPLE";
export const SCRL_HANDLE_LOADING_MULTIPLE = "SCRL_HANDLE_LOADING_MULTIPLE";
export const SCRL_HANDLE_SCROLL_LOADING_MULTIPLE = "SCRL_HANDLE_SCROLL_LOADING_MULTIPLE";
export const SCRL_HANDLE_SCROLL_POSITION_MULTIPLE = "SCRL_HANDLE_SCROLL_POSITION_MULTIPLE";
export const SCRL_HANDLE_SCROLL_APPEND_MULTIPLE = "SCRL_HANDLE_SCROLL_APPEND_MULTIPLE";
export const SCRL_HANDLE_SEARCH_INIT_MULTIPLE = "SCRL_HANDLE_SEARCH_INIT_MULTIPLE";
export const SCRL_HANDLE_SEARCH_CHANGE_MULTIPLE = "SCRL_HANDLE_SEARCH_CHANGE_MULTIPLE";
export const SCRL_UPDATE_LIST_MULTIPLE = "SCRL_UPDATE_LIST_MULTIPLE";
export const SCRL_RESET_LIST_MULTIPLE = "SCRL_RESET_LIST_MULTIPLE";
export const HANDLE_SCRL_LIST_MULTIPLE = "HANDLE_SCRL_LIST_MULTIPLE";
export const HANDLE_SCRL_DATA_MULTIPLE = "HANDLE_SCRL_DATA_MULTIPLE";
export const SCRL_HANDLE_MULTIPLE = "SCRL_HANDLE_MULTIPLE";
