import React, { useState, useCallback } from "react";
import { Asterisk, currencyFormatter } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { Select, DatePicker } from "antd";
import { Modal, Box } from "@mui/material";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { bankList } from "./bankList";
import { disableFutureData } from "../../../utils/helperFunctions";

const { Option } = Select;

const Cheque = ({ isLead = false, paymentAmount, studentName = "", sectionSchoolID, hostel_id, student_id, location, type, studentDetails }) => {
	const navigate = useNavigate();

	const [bankName, setBankName] = useState(null);
	const [chequeNumber, setChequeNumber] = useState(null);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [chequeImage, setChequeImage] = useState(null);
	const [formError, setFormError] = useState({});
	const [openSumbitModal, setOpenSumbitModal] = useState(false);

	const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
		if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
			console.log(acceptedFiles[0]);
			// Accepted file
			setFormError({ ...formError, chequeImage: null });
			setChequeImage(acceptedFiles[0]);
		} else {
			if (
				rejectedFiles &&
				rejectedFiles.length > 0 &&
				rejectedFiles[0].errors &&
				rejectedFiles[0].errors.length > 0 &&
				rejectedFiles[0].errors[0].code === "file-too-large"
			) {
				setFormError({
					...formError,
					chequeImage: (
						<span>
							The image size is larger than <strong>10 MB</strong>. Please reduce the image size.
						</span>
					),
				});
			} else {
				setFormError({
					...formError,
					chequeImage: (
						<span>
							Please upload a <strong>.png</strong> or <strong>.jpg</strong> image
						</span>
					),
				});
			}
			// setFormError({ ...formError, chequeImage: null });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { getInputProps, getRootProps } = useDropzone({
		multiple: false,
		maxSize: 10.01 * 1024 * 1024, // 10 MB
		accept: {
			"image/png": [".png"],
			"image/jpeg": [".jpg", ".jpeg"],
		},
		onDrop: handleFileDrop,
	});

	const checkFormHasErrors = () => {
		let flag = false;
		let temp = {};
		if (bankName === null || bankName === "") {
			temp = { ...temp, bankName: "please select bank name" };
			flag = true;
		}
		if (chequeNumber === null || chequeNumber === "" || !chequeNumber.trim()) {
			temp = { ...temp, chequeNumber: "Enter valid cheque number" };
			flag = true;
		} else if (chequeNumber.length < 6) {
			temp = { ...temp, chequeNumber: "Please enter a valid 6-digit cheque number." };
			flag = true;
		}
		if (chequeImage === null) {
			temp = { ...temp, chequeImage: "Please upload screenshot of cheque" };
			flag = true;
		}
		setFormError(temp);
		return flag;
	};

	const [loading, setLoading] = useState(false);

	const makeChequePayment = async () => {
		let formdata = new FormData();

		formdata.append("bank_name", bankName);
		formdata.append("payment_type", "cheque");
		formdata.append("amount", paymentAmount);
		formdata.append("cheque_id", chequeNumber);
		formdata.append("cheque_date", moment(selectedDate).format("YYYY-MM-DD"));
		formdata.append("cheque_file", chequeImage);
		formdata.append("payment_date", moment(selectedDate).format("YYYY-MM-DD"));

		if (type === "C") {
			!isLead && formdata.append("section_subject_id", sectionSchoolID);
		}

		if (type === "H") {
			formdata.append("hostel_id", hostel_id);
		}

		setLoading(true);

		const data = await Api.doUpload(
			"POST",
			formdata,
			type === "C" ? `${ApiUrl.RECEIVE_PAYMENT}${student_id}/` : `${ApiUrl.RECEIVE_HOSTEL_PAYMENT}${student_id}/`
		);

		setLoading(false);

		if (data && data.status) {
			if (type === "C") {
				navigate(`/payment/paymentSuccess`, {
					state: {
						isLead: isLead,
						payment_id: data.payload.id,
						section_id: location.state.section_id,
						school_id: location.state.school_id,
						student_id: student_id,
						amount: paymentAmount,
					},
				});
			} else if (type === "H") {
				navigate(`/payment/payment-success-hostel`, {
					state: {
						payment_id: data.payload.id,
						section_id: studentDetails.student_data.section_assoc.id,
						school_id: studentDetails.student_data.school_assoc.id,
						student_id: student_id,
					},
				});
			}
		} else {
			if (data && data.error_code === 2001) {
				Swal.fire("Error!", data.message, "error");
			} else {
				if (type === "C") {
					navigate(`/payment/paymentFailed`, {
						state: {
							student_id: student_id,
							section_id: location.state.section_id,
							school_id: location.state.school_id,
							school_name: location.state.school_name,
							section_name: location.state.section_name,
						},
						replace: true,
					});
				} else if (type === "H") {
					navigate(`/payment/payment-failed`, {
						state: { student_id: student_id },
					});
				}
			}
		}
	};

	return (
		<>
			<div className="margin_Bottom antd_dropdown student_filter_drop_header">
				<p className="student_filter_drop_header">
					Bank Name <Asterisk />
				</p>
				<Select
					style={{ width: "100%" }}
					placeholder="Select bank name"
					onChange={(value) => {
						setFormError({ ...formError, bankName: null });
						setBankName(value || "");
					}}
					value={bankName || undefined}
					showArrow
					allowClear
					showSearch
				>
					{bankList.map((options, index) => {
						return (
							<Option key={index} value={options}>
								{options}
							</Option>
						);
					})}
				</Select>
				{formError.bankName && <ErrorText text={formError.bankName} />}
			</div>

			<div className="margin_Bottom">
				<p className="student_filter_drop_header">
					Enter Cheque Number <Asterisk />
				</p>

				<input
					maxLength="50"
					className={`change_pw_input ${formError.chequeNumber ? "change_pw_input_error" : ""}`}
					placeholder="Enter cheque number"
					value={chequeNumber || ""}
					onChange={(e) => {
						setFormError({ ...formError, chequeNumber: null });
						let tempChequeNo = e.target.value.replace(/[^0-9]/g, "");
						if (tempChequeNo.length <= 6) {
							setChequeNumber(tempChequeNo);
						}
					}}
				/>
				{formError.chequeNumber && <ErrorText text={formError.chequeNumber} />}
			</div>

			<div className="margin_Bottom">
				<p className="student_filter_drop_header">
					Cheque Date <Asterisk />
				</p>

				<DatePicker
					allowClear={false}
					getPopupContainer={(triggerNode) => {
						return triggerNode.parentNode;
					}}
					className="attendance_date_picker"
					format={"DD/MM/YYYY"}
					value={selectedDate}
					onChange={(date) => {
						setSelectedDate(date);
					}}
					disabledDate={disableFutureData}
				/>
			</div>

			<div className="margin_Bottom">
				<p className="student_filter_drop_header">Cheque Amount:</p>

				<h3>{currencyFormatter(Number(paymentAmount))}</h3>
			</div>

			<div className="margin_Bottom upCheque" {...getRootProps()}>
				<p className="student_filter_drop_header">
					Upload Screenshot of Cheque <Asterisk />
				</p>
				<div className="d-flex btnWrap">
					{/* <button className="camertaBtn"><img src={cameraIcon} /></button> */}
					<div className="upbtn">
						<input className="fileup" {...getInputProps()} type="file" />{" "}
					</div>
				</div>
				{!chequeImage && !formError.chequeImage && (
					<span>
						Please upload .Jpg, .Png files upto 10Mb
						<br />
					</span>
				)}

				{formError.chequeImage && <ErrorText text={formError.chequeImage} />}
			</div>
			{chequeImage && (
				<div className="form-group cheque-m">
					<h5>
						<i className="fa fa-check-double" style={{ color: "#2CAA00" }}></i>
						{chequeImage.name.length > 25 ? `${chequeImage.name.substring(0, 25)}...` : chequeImage.name}
					</h5>
				</div>
			)}

			<div className="margin_Bottom">
				<button
					className={"confirmBtn"}
					onClick={() => {
						if (!checkFormHasErrors() && !loading) {
							setOpenSumbitModal(!openSumbitModal);
						}
					}}
				>
					Confirm
					{loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
				</button>
			</div>

			<Modal open={openSumbitModal}>
				<Box>
					<div className="view_cheque_modal d-column ">
						<span className="bank_deposit_modal_heading">Are you sure?</span>
						<span className="bank_deposit_modal_heading small ">
							Receiving ₹{currencyFormatter(Number(paymentAmount), false)} as cheque from {studentName}
						</span>
						<div className="mt-3 d-flex justify-content-flex-end" style={{ float: "left" }}>
							<button
								className="btn btn-md student_detail_no"
								onClick={() => {
									setOpenSumbitModal(!openSumbitModal);
								}}
							>
								No
							</button>
							<button
								className="btn btn-md student_detail_password_confirm ml-2"
								onClick={() => {
									setOpenSumbitModal(!openSumbitModal);
									makeChequePayment();
								}}
							>
								Yes
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default Cheque;
