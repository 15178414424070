import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import ToastMessage from "../../common/toastMessage";
import LeadForm from "./AddLeadForm";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Swal from "sweetalert2";
import Loader from "../../common/Loader";
import { State } from "country-state-city";
import { City } from "country-state-city";
import WalletIcon from "../../../assets/img/WalletIcon.svg";
import { currencyFormatter } from "../../../utils/helperFunctions";

const initialState = {
	name: "",
	mobile: "",
	state: "",
	city: "",
	centre: null,
	course: null,
	course_starting: null,
	balance: "",
};

const LeadAdd = () => {
	const { leadId } = useParams();
	const edit = !!leadId;
	const [leadData, setLeadData] = useState(initialState);
	const [leadError, setLeadError] = useState({});
	const [cityList, setCityList] = useState(null);
	const [courseList, setCourseList] = useState(null);
	const { centerList } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const [leadDetailsLoading, setLeadDetailsLoading] = useState(!!leadId);

	const navigate = useNavigate();

	React.useEffect(() => {
		if (leadData.centre) {
			getCourses();
		} else {
			handleLeadData({
				course: null,
				course_starting: null,
			});
			setCourseList([]);
		}
	}, [leadData.centre]);

	React.useEffect(() => {
		if (leadId) {
			getLeadDetails();
		}
	}, [leadId]);

	const handleLeadData = async (payload) => {
		let objKey = Object.keys(payload)[0];
		setLeadError((facultyError) => {
			return { ...facultyError, [objKey]: "" };
		});
		setLeadData((facultyData) => {
			return { ...facultyData, ...payload };
		});
		if (objKey === "mobile" && payload[objKey].length === 10) {
			const { data, status } = await Api.doFetch("POST", { mobile_number: payload[objKey] }, `${ApiUrl.CHECK_MOBILE}`);
			if (status && data.mobile_exists) {
				setLeadError((facultyError) => {
					return {
						...facultyError,
						[objKey]: data.is_student ? "Mobile no. already exists, please search from existing student list" : "Mobile no. already exists",
					};
				});
			}
		}
	};

	const getCourses = async () => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.CLASSES}?school=${leadData.centre}`);
		setCourseList(data);
	};

	const checkSubmit = () => {
		let flag = true;

		let temp = {};

		if (!(leadData.name && leadData.name.trim())) {
			temp = { ...temp, name: "Please enter student name" };
			flag = false;
		}
		if (leadData.mobile.length < 10) {
			temp = { ...temp, mobile: "Please enter valid mobile number" };
			flag = false;
		}
		if (!(leadData.state && leadData.state.trim())) {
			temp = { ...temp, state: "Please select state" };
			flag = false;
		} else if (!leadData.state) {
			temp = { ...temp, state: "Please select state" };
			flag = false;
		}
		if (!(leadData.city && leadData.city.trim())) {
			temp = { ...temp, city: "Please select city" };
			flag = false;
		} else if (!leadData.city) {
			temp = { ...temp, city: "Please select city" };
			flag = false;
		}
		if (!leadData.centre) {
			temp = { ...temp, centre: "Please select centre name" };
			flag = false;
		}
		if (!leadData.course) {
			temp = { ...temp, course: "Please select course name" };
			flag = false;
		}
		if (!leadData.course_starting) {
			temp = { ...temp, course_starting: "Please select course standard" };
			flag = false;
		}
		setLeadError(temp);
		return flag;
	};

	const saveLead = async () => {
		const formdata = new FormData();
		formdata.append("name", leadData.name);
		formdata.append("mobile_number", leadData.mobile);
		formdata.append("state", leadData.state);
		formdata.append("city", leadData.city);
		formdata.append("center", leadData.centre);
		formdata.append("course", leadData.course);
		formdata.append("class_standard", leadData.course_starting);

		setLoading(true);
		const payload = await Api.doUpload(edit ? "PATCH" : "POST", formdata, edit ? `${ApiUrl.UPDATE_LEAD}/${leadId}` : ApiUrl.CREATE_LEAD);
		setLoading(false);
		if (!payload.status) {
			Swal.fire("Error!", `${payload.message}`, "error");
		} else {
			toast(<ToastMessage text={edit ? "Details edited successfully." : "Lead created successfully."} />);
			navigate(`/lead/search`);
		}
	};

	const getLeadDetails = async () => {
		setLeadDetailsLoading(true);
		const payload = await Api.doFetch("GET", {}, `${ApiUrl.LEAD}/${leadId}`);
		if (payload.status) {
			const { data } = payload;
			console.log(data);
			const stateIndex = State.getStatesOfCountry("IN").findIndex((stateData) => stateData.name === data.state);
			setCityList(City.getCitiesOfState("IN", State.getStatesOfCountry("IN")[stateIndex].isoCode));
			const leadObj = {
				name: data.name,
				mobile: data.mobile_number,
				state: data.state,
				city: data.city_assoc,
				centre: data.centre_id,
				course: data.course_id,
				course_starting: data.class_standard,
				balance: data.wallet_balance,
			};

			setLeadData({
				...leadData,
				...leadObj,
			});
			setLeadDetailsLoading(false);
		}
	};

	return (
		<div className="course_add_container ">
			<div className="add_lead_header d-flex align-items-center justify-content-between mb-2">
				<div className="d-flex align-items-center">
					<ArrowBackIcon
						sx={{ color: "#A3AED0", width: 25, height: 25, cursor: "pointer" }}
						onClick={() => {
							if (leadId) {
								navigate("/lead/list");
							} else navigate("/lead");
						}}
					/>

					<span className="reversal_detail_header_text ml-2">{edit ? "Edit details" : "Add New Lead"}</span>
				</div>

				{edit && (
					<div className="d-flex align-items-center mywallet-container-edit" onClick={() => navigate(`/mywallet-history/${leadId}`)}>
						<img src={WalletIcon} alt="wallet icon" />
						<div style={{ fontWeight: "500" }}>
							<span style={{ color: "#F47B26" }}>my</span>
							<span style={{ color: "#1F3061" }}>wallet</span> :
						</div>
						{!leadDetailsLoading && <div style={{ fontWeight: "600" }}>{currencyFormatter(leadData.balance)}</div>}
					</div>
				)}
			</div>

			{leadDetailsLoading ? (
				<div className="row pt-5">
					<div className="loader_scroll_container">
						<Loader />
					</div>
				</div>
			) : (
				<>
					<LeadForm
						data={leadData}
						handleLeadData={handleLeadData}
						leadError={leadError}
						cityList={cityList}
						setCityList={setCityList}
						centreList={centerList}
						courseList={courseList}
						edit={edit}
					/>
					<div className="mt-3 d-flex justify-content-between">
						<button className="btn btn-md add_student_back" onClick={() => navigate("/lead")}>
							Cancel
						</button>
						<button
							className="btn btn-md add_student_next ml-2"
							onClick={() => {
								if (loading) return;
								if (checkSubmit()) {
									if (Object.values(leadError).filter((x) => x).length > 0) {
										return;
									}
									saveLead();
								}
							}}
						>
							Save
							{loading && <span className="ml-2 spinner spinner-white mr-2"></span>}
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default LeadAdd;
