import React, { useEffect, useState } from "react";
import Error from "../../common/errorText";
import { useSelector } from "react-redux";
import { roleConstant } from "../../../utils/helperFunctions";

const HostelFeePayment = ({
    goToPaymentHostel,
    handlePaymentMethodHostel,
    paymentMethodHostel,
    hostelPaymentAmount,
    updateHostelInputValue,
    toggle,
    isSociety = false
}) => {

    const { roleList } = useSelector(state => state.auth);

    const [error, setError] = useState("");

    useEffect(() => {
        setError("");
    }, [toggle])

    const [isStudentManager, setIsStudentManager] = useState(false);

    useEffect(() => {
        if (roleList && roleList.length > 0 && roleList.findIndex(x => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
            setIsStudentManager(true);
        }
    }, [roleList])

    const getClassName = (type, societyCheck = true) => {
        let className = paymentMethodHostel === type ? "contain active" : "contain";

        if (societyCheck && isSociety) {
            className += " d-none"
        }

        return className;
    }

    return (
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close payment_pay_close_button" data-dismiss="modal">
                    &times;
                </button>
                <h4 className="modal-title">Payment Collection</h4>
            </div>
            <div className="modal-body">
                <div className="">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="text">Enter Amount</label>
                                <div
                                    className="input-group discountWrpField col-sm-6 col-md-4 col-lg-3"
                                    style={{ width: "40%" }}
                                >
                                    <span className="input-group-addon">&#8377;</span>
                                    <input
                                        style={{ width: "100%" }}
                                        type="text"
                                        placeholder="Enter Amount"
                                        className="form-control discountWrp"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        autoComplete="off"
                                        value={hostelPaymentAmount}
                                        onChange={(event) => {
                                            setError("");
                                            updateHostelInputValue(event);
                                        }}
                                    />
                                </div>
                                {
                                    error &&
                                    <Error text={error} />
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="pwd">Mode of payment:</label>
                                <div className="">
                                    {/* <label className={paymentMethodHostel === 'icici' ? 'contain active' : 'contain'}>Online Payment (ICICI)
                                        <input type="radio" name="paymentMethodHostel" checked={paymentMethodHostel === 'icici'} value="icici" onChange={(event) => handlePaymentMethodHostel(event)} />
                                        <span className="checkmark"></span>
                                        </label> */}
                                    <label
                                        className={getClassName("cash", false)}
                                    >
                                        Cash
                                        <input
                                            type="radio"
                                            name="paymentMethodHostel"
                                            checked={paymentMethodHostel === "cash"}
                                            value="cash"
                                            onChange={(event) =>
                                                handlePaymentMethodHostel(event)
                                            }
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label
                                        className={getClassName("cheque", false)}
                                    >
                                        Cheque
                                        <input
                                            type="radio"
                                            name="paymentMethodHostel"
                                            checked={paymentMethodHostel === "cheque"}
                                            value="cheque"
                                            onChange={(event) =>
                                                handlePaymentMethodHostel(event)
                                            }
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label
                                        className={getClassName("swipe")}
                                    >
                                        POS (Swipe Machine)
                                        <input
                                            type="radio"
                                            name="paymentMethodHostel"
                                            checked={paymentMethodHostel === "swipe"}
                                            value="swipe"
                                            onChange={(event) =>
                                                handlePaymentMethodHostel(event)
                                            }
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label
                                    className={getClassName("ezetap")}
                                >
                                    new POS (ICICI)
                                    <input
                                        type="radio"
                                        name="paymentMethodHostel"
                                        checked={paymentMethodHostel === "ezetap"}
                                        value="ezetap"
                                        onChange={(event) => handlePaymentMethodHostel(event)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <label
                                    className={getClassName("link")}
                                >
                                    Share Payment Link
                                    <input
                                        type="radio"
                                        name="paymentMethodHostel"
                                        checked={paymentMethodHostel === "link"}
                                        value="link"
                                        onChange={(event) => handlePaymentMethodHostel(event)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-group">
                                <label
                                    className={getClassName("razorpay")}
                                >
                                    Online Payment (Razor Pay)
                                    <input
                                        type="radio"
                                        name="paymentMethodHostel"
                                        checked={paymentMethodHostel === "razorpay"}
                                        value="razorpay"
                                        onChange={(event) => handlePaymentMethodHostel(event)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-group">
                                {
                                    isStudentManager &&
                                    <label
                                        className={getClassName("bankdeposit", false)}
                                    >
                                        Bank Deposit
                                        <input
                                            type="radio"
                                            name="paymentMethodHostel"
                                            checked={paymentMethodHostel === "bankdeposit"}
                                            value="bankdeposit"
                                            onChange={(event) => handlePaymentMethodHostel(event)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                }

                                <label
                                    className={getClassName("nocostemi")}
                                >
                                    No cost EMI
                                    <input
                                        type="radio"
                                        name="paymentMethodHostel"
                                        checked={paymentMethodHostel === "nocostemi"}
                                        value="nocostemi"
                                        onChange={(event) => handlePaymentMethodHostel(event)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer floatleft">
                <button
                    type="button"
                    data-dismiss="modal"
                    style={{ display: "none" }}
                    id="close_modal1"
                />
                <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                        if(+hostelPaymentAmount > 0){
                            goToPaymentHostel();
                            document.getElementById("close_modal1").click();
                        }else{
                            setError("Please enter valid Amount");
                        }
                    }}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default HostelFeePayment;