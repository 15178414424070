import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchStudent from "../../../assets/img/search.svg";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import AddCircle from "../../../assets/img/add_circle.svg";
import StudentCard from "../../student/search/studentCard";
import { useDispatch, useSelector } from "react-redux";
import { HANDLE_STUDENT_SEARCH, RESET_STUDENT_SEARCH } from "../../../actions/types";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Empty from "../../../assets/img/empty_student.svg";
import AdvancePaymentModal from "./paymentModal";
import OtpVerificationModal from "./OtpVerificationModal";
import ToastMessage from "../../common/toastMessage";
import { toast } from "react-toastify";
import { message } from "antd";

const openInNewTab = (href) => {
	Object.assign(document.createElement("a"), {
		target: "_blank",
		rel: "noopener noreferrer",
		href: href,
	}).click();
};

const LeadList = () => {
	const navigate = useNavigate();
	const { searchText, studentList, studentData, skip, limit, continueScroll } = useSelector((state) => state.studentSearch);
	const [tempSearchText, setTempSearchText] = useState(searchText);
	const [scrollLoading, setScrollLoading] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [selectedLead, setSelectedLead] = useState(null);
	const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
	const [otp, setOtp] = useState("");
	const [otpError, setOtpError] = useState(null);
	const [hasCentre, setHasCentre] = useState(null);
	const [openModal, toggleModal] = useState(false);
	const [sendingOtp, setSendingOtp] = useState(false);
	const [verifying, setVerifying] = useState(false);

	const closeModal = () => {
		toggleModal(false);
	};
	const sendOtp = async (retry, id) => {
		const selectedId = id ? id : selectedLead;
		setSendingOtp(true);
		message.config({
			top: 100,
			duration: 2,
			maxCount: 1,
		});
		message.loading("Please wait generating OTP...", 0);
		const status = await Api.doFetch("POST", {}, `${ApiUrl.LEAD_VERIFY_MOBILE}/${selectedId}`).then((res) => {
			setOtp("");
			setSendingOtp(false);
			!sendingOtp && message.destroy();
			if (res.status) {
				toggleModal(true);
				toast(<ToastMessage type="success" text={retry ? "OTP resent successfully" : "OTP sent successfully"} />);
				return true;
			} else {
				toast(<ToastMessage type="error" text="Mobile number verification failed" />);
				return false;
			}
		});
		return status;
	};

	const verifyOtp = async () => {
		let copyList = studentList;
		let index = studentList.findIndex((s) => s.id === selectedLead);
		setVerifying(true);
		await Api.doFetch(
			"POST",
			{
				otp: otp,
			},
			`${ApiUrl.LEAD_VERIFY_OTP}/${selectedLead}`
		).then((res) => {
			setVerifying(false);
			if (res.status) {
				if (index !== -1) {
					copyList[index].is_mobile = true;
					dispatch({
						type: HANDLE_STUDENT_SEARCH,
						payload: {
							studentList: copyList,
						},
					});
				}
				closeModal();

				toast(<ToastMessage type="success" text="OTP verified successfully" />);
				setTimeout(() => {
					setAdvancePaymentModalOpen(true);
				}, 1000);
			} else {
				toast(<ToastMessage type="error" text="Mobile number verification failed" />);
				setOtpError("Invalid OTP, please try again");
			}
		});
	};

	const [advancePaymentModalOpen, setAdvancePaymentModalOpen] = useState(false);

	const dispatch = useDispatch();

	React.useEffect(() => {
		getStudent();
		return () => {
			dispatch({ type: RESET_STUDENT_SEARCH });
		};
	}, []);

	React.useEffect(() => {
		if (skip > 0) {
			getStudent();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skip, limit]);

	const getStudent = async () => {
		if (+skip === 0) {
			let url = `${ApiUrl.LEAD}?skip=${skip}&limit=${limit}`;
			setScrollLoading(true);
			const payload = await Api.doFetch("GET", {}, url);
			if (!payload.status) return;
			const data = payload.data.data;
			setScrollLoading(false);
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					studentData: data && data.length > 0 ? data : [],
					studentList: data && data.length > 0 ? data : [],
					continueScroll: data && +data.length === +limit,
				},
			});
		} else {
			let url = `${ApiUrl.LEAD}?skip=${skip}&limit=${limit}${tempSearchText.trim() ? `&search=${tempSearchText.trim()}` : ""}`;
			setScrollLoading(true);
			const payload = await Api.doFetch("GET", {}, url);
			if (!payload.status) return;
			const data = payload.data.data;
			setScrollLoading(false);
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					searchFilterApplied: searchText ? true : false,
					studentData: data && data.length > 0 ? [...studentData, ...data] : studentData,
					studentList: data && data.length > 0 ? [...studentList, ...data] : studentList,
					continueScroll: data && +data.length === +limit,
				},
			});
		}
	};

	const searchStudent = async () => {
		setSearchLoading(true);
		let url = `${ApiUrl.LEAD}?skip=${0}&limit=${limit}&search=${tempSearchText.trim()}`;
		const payload = await Api.doFetch("GET", {}, url);
		if (!payload.status) return;
		const data = payload.data.data;
		setSearchLoading(false);
		dispatch({
			type: HANDLE_STUDENT_SEARCH,
			payload: {
				studentData: data && data.length > 0 ? data : [],
				studentList: data && data.length > 0 ? data : [],
				continueScroll: data && +data.length === +limit,
				searchText: tempSearchText,
			},
		});
	};

	const handleScroll = async (event) => {
		const e = event.target;
		let threshold = e.scrollHeight - e.scrollTop <= e.clientHeight + 150;

		if (threshold && continueScroll) {
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					skip: skip + limit,
					continueScroll: false,
				},
			});
		}
	};

	const closeAdvancePaymentModal = () => {
		setAdvancePaymentModalOpen(false);
	};

	return (
		<div className="course_add_container ">
			<AdvancePaymentModal
				leadID={selectedLead}
				hasCentre={hasCentre}
				advancePaymentModalOpen={advancePaymentModalOpen}
				closeAdvancePaymentModal={closeAdvancePaymentModal}
			/>
			{openModal && (
				<OtpVerificationModal
					openModal={openModal}
					closeModal={closeModal}
					mobile={selectedMobileNumber}
					otp={otp}
					setOtp={setOtp}
					verifyOtp={verifyOtp}
					verifying={verifying}
					sendOtp={sendOtp}
					otpError={otpError}
					setOtpError={setOtpError}
					studentId={studentData?.id}
					// nextStep={() => setStep(2)}
				/>
			)}
			<div className="add_lead_header d-flex align-items-center justify-content-between mb-5">
				<div className="d-flex align-items-center">
					<ArrowBackIcon sx={{ color: "#A3AED0", width: 25, height: 25, cursor: "pointer" }} onClick={() => navigate("/lead/search")} />

					<span className="reversal_detail_header_text width_150  ml-2">List of all leads</span>
				</div>
				<div className="d-flex align-items-center justify-content-flex-end flex-grow-1">
					<div className="lead_search_container">
						<img
							src={SearchStudent}
							alt="search"
							className="student_search_image"
							style={{
								top: "50%",
								transform: "translate(0, -50%)",
							}}
						/>

						<input
							className={`student_search_input lead_search_box lead_search_input_border ${
								tempSearchText.trim() !== "" && "lead_search_input_border"
							}`}
							value={tempSearchText}
							onChange={(e) => {
								setTempSearchText(e.target.value);
								if (e.target.value === "" && searchText) {
									dispatch({ type: RESET_STUDENT_SEARCH });
									getStudent();
								}
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									dispatch({ type: RESET_STUDENT_SEARCH });
									if (!searchLoading && tempSearchText.trim()) {
										searchStudent();
									}
								}
							}}
							placeholder="Search by name / number"
							// disabled={searchLoading ? true : false}
						/>
					</div>
					<button
						className={`btn btn-md lead_search_button small ${tempSearchText.trim() === "" && "disabled"}`}
						onClick={() => {
							dispatch({ type: RESET_STUDENT_SEARCH });
							if (!searchLoading && tempSearchText.trim()) {
								searchStudent();
							}
						}}
					>
						Search
						{searchLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
					</button>
				</div>
			</div>
			<div className="lead_scroll_container" onScroll={handleScroll}>
				{studentList.map((studentData, index) => {
					return (
						<StudentCard
							studentData={studentData}
							key={index}
							installmentMissed={
								studentData && (studentData.last_installment_due_on || studentData.hostel_last_installment_due_on) ? true : false
							}
							installmentPlanNotCreated={false}
							redirect={() => {
								openInNewTab(`/student/add?lead-id=${studentData.id}`);
							}}
							onEditLead={() => {
								openInNewTab(`/lead/${studentData.id}`);
							}}
							style={index === 0 ? { marginTop: "0rem" } : {}}
							isLeadList
							onCollectAdvance={() => {
								studentData.centre_id !== null ? setHasCentre(true) : setHasCentre(false);
								setSelectedMobileNumber(studentData?.mobile_number);
								console.log(studentData.id);
								setSelectedLead(studentData.id);

								if (studentData.is_mobile) {
									setAdvancePaymentModalOpen(true);
								} else {
									sendOtp(false, studentData.id);
								}
							}}
						/>
					);
				})}
				{(searchLoading || scrollLoading) && (
					<div className="loader_scroll_container">
						<Loader />
					</div>
				)}
				{!searchLoading && !scrollLoading && studentList.length === 0 && (
					<div className="student_search_empty_container">
						<div className="student_search_empty_img_container">
							<img src={Empty} alt="not found" className="student_search_empty_img" />
						</div>
						<p className="student_search_empty_header">{!searchText ? "No student records found" : "No results found"}</p>

						<span className="student_search_empty_label">
							{!searchText ? "Try adding a new lead to find results here" : "Try adjusting your search history to find the best results"}
						</span>
					</div>
				)}
				{!searchLoading && !scrollLoading && (
					<div
						className="add_course_container"
						onClick={() => {
							navigate("/lead/add");
						}}
					>
						<div className="add_course_text_container">
							<div className="d-column align-items-center ">
								<img src={AddCircle} alt="add" className="add_student_img" />
								<span className="add_student_container_text">Add New Lead</span>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LeadList;
