import React, { useState, useRef, useCallback, useEffect } from "react";
import Filter from "../common/financeFilter";
import DownloadIcon from "../../../assets/img/downloadIcon.svg";
import { CustomFinanceTooltip } from "../common/handleTooltip";
import RedeemedCard from "./redeemedCard";
import moment from "moment";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import FinanceLoader from "../common/FinanceLoader";
import Empty from "../../../assets/img/empty_student.svg";
import Loader from "../../common/Loader";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import { getMyclassroomCentre } from "../../../utils/helperFunctions";
import { useSelector, useDispatch } from "react-redux";
import { SCRL_HANDLE_SEARCH_INIT, SCRL_RESET_LIST } from "../../../actions/types";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

import SearchImg from "../../../assets/img/search.svg";

const RedeemedPayments = () => {
	const dispatch = useDispatch();

	const [filterLoading, setFilterLoading] = useState(false);
	const { myclassroomCentre } = useSelector((state) => state.common);
	const [searchText, setSearchText] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [centre, setCentre] = useState([]);
	const [dateError, setDateError] = useState("");

	const searchTextRef = useRef(searchText);
	const startDateRef = useRef(startDate);
	const endDateRef = useRef(endDate);
	const centreRef = useRef(centre);

	const { skip, limit } = useSelector((state) => state.scrollingList);

	const handleSearchText = (e) => {
		setSearchText(e.target.value);
		searchTextRef.current = e.target.value;
		handleSearch(e.target.value);
	};

	useEffect(() => {
		if (myclassroomCentre && myclassroomCentre.length === 0) {
			getMyclassroomCentre();
		}

		return () => {
			dispatch({ type: SCRL_RESET_LIST });
		};
	}, []);

	const filterApplied = () => {
		if (searchTextRef.current || startDateRef.current || endDateRef.current || (centreRef.current && centreRef.current.length > 0)) {
			return true;
		}

		return false;
	};

	const getRedeemedPayment = useCallback(async ({ skip = 0, limit = 10 }) => {
		let payload = {
			skip,
			limit,
		};

		if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
			payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
			payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
		}

		if (searchTextRef && searchTextRef.current) {
			payload.search = searchTextRef.current;
		}

		if (centreRef && centreRef.current.length > 0) {
			payload.centre_ids = centreRef.current;
		}

		const data = await Api.doFetch("POST", payload, ApiUrl.REDEEMED_PAYMENTS);

		if (data?.status === true) {
			const payload = data.data;

			if (payload) {
				const { data: items, total, skip, limit } = payload;

				return {
					items,
					total,
					skip,
					limit,
				};
			}
		}

		setFilterLoading(false);

		return null;
	}, []);

	const getRedeemedPaymentDetails = async () => {
		if (moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
			setDateError("From date cannot be greater than To date");
			return;
		}

		setFilterLoading(true);
		const data = await getRedeemedPayment({
			skip,
			limit,
		});

		dispatch({
			type: SCRL_HANDLE_SEARCH_INIT,
			payload: {
				list: (data && data.items) || [],
				skip: 0,
				total: (data && data.total) || 0,
				scrollLoading: false,
			},
		});

		setFilterLoading(false);
	};

	const [scrollContainer, { loading, scrollLoading, list: redeemedPaymentList, handleScroll, handleSearch }] = useScrollingList({
		listKey: "redeemed-payment",
		getData: getRedeemedPayment,
	});

	const [downloadLoading, setDownloadLoading] = useState(false);

	const downloadRedmeedPayment = async () => {
		if (!filterApplied() || downloadLoading) {
			return;
		}

		let payload = {};

		if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
			payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
			payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
		}

		if (searchText && searchText.trim().length > 0) {
			payload.search = searchText;
		}

		if (centreRef && centreRef.current.length > 0) {
			payload.centre_ids = centreRef.current;
		}

		setDownloadLoading(true);

		const data = await Api.doFetch("POST", payload, ApiUrl.REDEEMED_PAYMENTS);

		setDownloadLoading(false);

		if (data?.status) {
			const workbook = new ExcelJS.Workbook();

			const sheet = workbook.addWorksheet("Pending Cheque details");

			sheet.columns = [
				{ header: "Ack No", key: "Ack No" },
				{ header: "Course Changed", key: "Course Changed" },
				{ header: "Centre Changed", key: "Centre Changed" },
				{ header: "Student ID", key: "Student ID" },
				{ header: "Student name", key: "Student name" },
				{ header: "Student number", key: "Student number" },
				{ header: "Centre name", key: "Centre name" },
				{ header: "Redeemed By", key: "Redeemed By" },
				{ header: "Amount", key: "Amount" },
				{ header: "Entry date", key: "Entry date" },
				{ header: "Payment Type", key: "Payment Type" },
			];

			data?.data?.data.map((row) => {
				let obj = {
					"Ack No": row?.ack_no || "",
					"Course Changed": row?.centre_changed ? "Yes" : "No",
					"Centre Changed": row?.course_changed ? "Yes" : "No",
					"Student ID": row?.student_id || "",
					"Student name": row?.student_name || "",
					"Student number": row?.mobile_number || "",
					"Centre name": row?.centre_name || "",
					"Redeemed By": row?.redeemed_by ? row?.redeemed_by : "",
					Amount: row?.amount_paid || "",
					"Entry date": row?.created_on ? moment(row?.created_on).format("DD/MM/YYYY") : "",
					"Payment Type": row?.payment_type || "",
				};

				sheet.addRow(obj);
			});

			workbook.xlsx.writeBuffer().then(function (buffer) {
				const blob = new Blob([buffer], { type: "application/xlsx" });
				saveAs(blob, "Redeemed Payments details.xlsx");
			});
		} else {
			Swal.fire("Error!", data?.message || "Internal Server Error", "error");
		}
	};

	if (loading) {
		return <FinanceLoader text="Loading payment details..." />;
	}
	return (
		<>
			<div className="course_list_container">
				<div ref={scrollContainer} className="pending-cheques-scroll-container" onScroll={handleScroll}>
					<div className="course_list_header">
						<div className="d-flex">
							<div className="pending-cheques__heading_text">Redeemed payments</div>
						</div>

						<div className="d-flex align-items-center course_search_wrapper">
							<img src={SearchImg} alt="search" className="course_search_image" />

							<input className="course_search_input" value={searchText} onChange={handleSearchText} placeholder="Search a payment" />
							<button
								className={
									searchText && searchText.trim().length > 0 && !filterLoading
										? "btn btn-md payment_details_search_button "
										: "btn btn-md payment_details_search_button disabled"
								}
								onClick={() => {
									if (searchText && searchText.trim().length > 0 && !filterLoading) {
										getRedeemedPaymentDetails();
									}
								}}
							>
								Search
							</button>
						</div>
					</div>

					<Filter
						startDate={startDate}
						endDate={endDate}
						centre={centre}
						setStartDate={setStartDate}
						startDateRef={startDateRef}
						setEndDate={setEndDate}
						endDateRef={endDateRef}
						setCentre={setCentre}
						centreRef={centreRef}
						dateError={dateError}
						setDateError={setDateError}
						getDetails={getRedeemedPaymentDetails}
						filterLoading={filterLoading}
					/>

					<div className="d-flex export_row">
						<CustomFinanceTooltip title="Apply filters to export the data" placement="left" hideTooltip={filterApplied()}>
							<span className={filterApplied() ? "cursor-pointer" : "disabled-content"} onClick={downloadRedmeedPayment}>
								{downloadLoading ? (
									<span className="spinner spinner-white mr-3" />
								) : (
									<img src={DownloadIcon} alt="Download" className="export_download" />
								)}
								Export
							</span>
						</CustomFinanceTooltip>
					</div>
					{filterLoading ? (
						<div className="loader_scroll_container">
							<Loader />
						</div>
					) : redeemedPaymentList?.length === 0 ? (
						<div className="student_search_empty_container mt-5">
							<div className="student_search_empty_img_container">
								<img src={Empty} alt="not found" className="student_search_empty_img" />
							</div>

							<p className="student_search_empty_header">No Payment details found</p>
							<span className="student_search_empty_label">Try adjusting your search to find</span>
							<span className="student_search_empty_label">the best results</span>
						</div>
					) : (
						redeemedPaymentList &&
						redeemedPaymentList.map((payment, index) => {
							return <RedeemedCard payment={payment} />;
						})
					)}
					{!loading && scrollLoading && (
						<div className="loader_scroll_container">
							<Loader />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default RedeemedPayments;
