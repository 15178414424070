import React from "react";
import { useMediaQuery } from "@mui/material";
import { currencyFormatter } from "../../../utils/helperFunctions";
import Loader from "../../common/Loader";
import WalletHistoryCard from "./walletHistoryCard";

const MywalletTab = ({ walletData, loading, refreshData }) => {
	const isMobile = useMediaQuery("(max-width: 850px)");

	if (loading) {
		return (
			<div className="loader_scroll_container">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<div className={`courseDetailWrap${isMobile ? " is-mobile" : ""}`}>
				<div className="tab_card_div">
					<div className="cash-details-header course_list_card" style={{ width: "100%" }}>
						<div className="course_divider1">
							<div className="heading-header-light">
								<span style={{ color: "#F47B26" }}>my</span>
								<span style={{ color: "#1F3061" }}>wallet</span> balance
							</div>{" "}
							<div className="course_list_card_value" style={{ fontSize: "unset" }}>
								₹{currencyFormatter(walletData?.payment_summary?.wallet_balance, false)}
							</div>
						</div>
						<div className="course_divider3">
							<div className="heading-header-light">Added to wallet</div>{" "}
							<div className="course_list_card_value" style={{ fontSize: "unset" }}>
								+ ₹{currencyFormatter(walletData?.payment_summary?.added_amount, false)}
							</div>
						</div>
						<div className="course_divider3">
							<div className="heading-header-light">Redeemed</div>{" "}
							<div className="course_list_card_value" style={{ fontSize: "unset" }}>
								₹{currencyFormatter(walletData?.payment_summary?.redeemed, false)}
							</div>
						</div>
						<div className="course_divider3">
							<div className="heading-header-light">Unrealised</div>{" "}
							<div className="course_list_card_value" style={{ fontSize: "unset" }}>
								₹{currencyFormatter(walletData?.payment_summary?.unrealised_amount, false)}
							</div>
						</div>
					</div>

					<div className={`course_list_card${isMobile ? " d-none" : ""}`} style={{ width: "100%" }}>
						<div className="course_divider1 myWalletDiv">
							<div className="heading-header-light">Transaction Details</div>
						</div>
						<div className="course_divider2 big">
							<div className="heading-header-light">Ack.no.</div>
						</div>
						<div className="course_divider1">
							<div className="heading-header-light">Centre Name</div>
						</div>
						<div className="course_divider2 big">
							<div className="heading-header-light">Course Type</div>
						</div>
						<div className="course_divider2 big">
							<div className="heading-header-light">Student State</div>
						</div>
						<div className="course_divider2">
							<div className="heading-header-light">Amount</div>
						</div>
						<div className="course_divider2">
							<div className="heading-header-light">Status</div>
						</div>
					</div>

					{walletData?.data && walletData?.data.length > 0 ? (
						walletData.data.map((item, index) => {
							return (
								<WalletHistoryCard
									item={item}
									refreshData={refreshData}
									centreChanged={walletData?.data[index + 1] ? item.student_centre !== walletData?.data[index + 1].student_centre : false}
									courseChanged={
										walletData?.data[index + 1] ? item.student_course_type !== walletData?.data[index + 1].student_course_type : false
									}
								/>
							);
						})
					) : (
						<div style={{ textAlign: "center", padding: "54px 0 30px", width: "100%" }}>
							<h4>No transaction history found.</h4>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MywalletTab;
