import React, { useState } from "react";
import WarningImg from "../../../assets/img/warning.svg";
import LeftArrow from "../../../assets/img/left_arrow.svg";

import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import Box from "@mui/material/Box";
import TickWhite from "../../../assets/img/tick_white.svg";
import { toast } from "react-toastify";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { currencyFormatter, generateProfileInitials } from "../../../utils/helperFunctions";

import LockcontentImg from "../../../assets/img/lock_content.svg";
import UnLockcontentImg from "../../../assets/img/unlock_content.svg";
import IncludesTab from "../../../assets/img/Tab.svg";

import swal from "sweetalert2";
import { segmentEvents } from "../../../utils/constants";
import GreenTick from "../../../assets/img/lead/greenTick.svg";

import WalletIcon from "../../../assets/img/WalletIcon.svg";

const modalStyle = {
	position: "absolute",
	top: "40%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	width: "90%",
	maxWidth: 550,
	padding: "3rem 3rem",
	borderRadius: 4,
};

const ToastMessage = ({ text }) => (
	<div
		className="reversal_toast"
		style={{
			"--toast-status": "#35CB00",
		}}
	>
		<img src={TickWhite} alt={"success"} className="mr-2" style={{ width: "1.2rem" }} />
		{text}
	</div>
);

const DeleteOption = ({ value }) => {
	return (
		<FormControlLabel
			className="delete_profile_radio_option"
			value={value}
			control={
				<Radio
					sx={{
						"& .MuiSvgIcon-root": {
							fontSize: 22,
						},
						color: "#000000",
						"&.Mui-checked": {
							color: "#000000",
						},
					}}
				/>
			}
			label={value}
		/>
	);
};

const StudentCard = ({
	studentData = {},
	installmentMissed,
	style = {},
	deleteProfile,
	goback,
	optionsNeeded = true,
	fromFeeSummary = false,
	getStudentDetails,
}) => {
	let options = [
		"Edit Details",
		"Reset Password",
		// "Delete Profile"
	];

	const navigate = useNavigate();
	const isMobile = useMediaQuery("(max-width:600px)");

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (option) => {
		if (option === "Reset Password") {
			setPasswordModal(true);
		} else if (option === "Edit Details") {
			console.log("click");
			if (fromFeeSummary) {
				navigate(`/student/edit/${studentData.student_data.id}`, {
					state: {
						fromFeeSummary: true,
					},
				});
			} else {
				navigate(`/student/edit/${studentData.student_data.id}`);
			}
		}
		// else if (e.nativeEvent.target.outerText === "Delete Profile") {
		// 	setDeleteReason("Issued Refund");
		// 	setDeleteProfileModal(true);
		// }
		setAnchorEl(null);
	};

	const [passwordModal, setPasswordModal] = useState(false);

	const closePasswordModal = () => {
		setPasswordModal(false);
	};

	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
	const resetPassword = async () => {
		var formdata = new FormData();

		formdata.append("user_id", studentData.student_data.user_assoc);

		setResetPasswordLoading(true);

		await Api.doUpload("POST", formdata, ApiUrl.STUDENT_RESET_PASSWORD);

		setResetPasswordLoading(false);

		toast(<ToastMessage text={"Password reset successfully."} />);

		Api.trackEvent(segmentEvents.STUDENT.RESET_PASSWORD);

		closePasswordModal();
	};

	const [deleteProfileModal, setDeleteProfileModal] = useState(false);
	const [deleteReason, setDeleteReason] = useState("Issued Refund");

	const closeDeleteProfile = () => {
		setDeleteProfileModal(false);
	};

	const [deleteStudentLoading, setDeleteStudentLoading] = useState(false);

	const deleteStudent = async () => {
		var formData = new FormData();
		formData.append("reason", deleteReason);

		setDeleteStudentLoading(true);

		const data = await Api.doUpload("POST", formData, `${ApiUrl.DELETE_STUDENT}${studentData.student_data.id}/`);

		setDeleteStudentLoading(false);

		if (data) {
			toast(<ToastMessage text="Profile deleted successfully." />);
			closeDeleteProfile();
			navigate("/student");
		}
	};

	const [contentModal, setContentModal] = useState("");
	const [lockLoading, setLockLoading] = useState(false);

	const closeContentModal = () => {
		setContentModal("");
	};

	const toggleLockProfile = async (lock_profile) => {
		setLockLoading(true);

		const data = await Api.doFetch("PATCH", { lock_profile }, `${ApiUrl.ACCESS_LOCK}/${studentData.student_data.id}`);

		setLockLoading(false);

		if (data && data.status) {
			Api.trackEvent(lock_profile ? segmentEvents.STUDENT.ACCESS_LOCKED : segmentEvents.STUDENT.ACCESS_UNLOCKED);
			toast(<ToastMessage text={data.message} />);
			getStudentDetails && getStudentDetails();
		} else {
			if (data && data.message) {
				swal.fire("Error!", data.message, "error");
			} else {
				swal.fire("Error!", "Internal Server Error", "error");
			}
		}
		closeContentModal();
	};

	return (
		<>
			<Modal open={deleteProfileModal} onClose={closeDeleteProfile} className="student_detail_delete_profile">
				<Box sx={modalStyle}>
					<p className="reversal_accept_text">Are you sure you want to delete this profile?</p>

					<p className="student_detail_delete_sub_header">Once deleted this profile will be permanently removed</p>

					<div>
						<FormControl>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue="female"
								name="radio-buttons-group"
								value={deleteReason}
								onChange={(e) => {
									setDeleteReason(e.target.value);
								}}
							>
								<DeleteOption value="Issued Refund" />
								<DeleteOption value="Dropped Out" />
								<DeleteOption value="Test Id" />
								<DeleteOption value="Others" />
							</RadioGroup>
						</FormControl>
					</div>

					<div className="mt-3" style={{ float: "right" }}>
						<button className="btn btn-md student_detail_no" onClick={closeDeleteProfile}>
							No
						</button>
						<button
							className="btn btn-md student_detail_delete_confirm ml-2"
							onClick={() => {
								if (!deleteStudentLoading) {
									deleteStudent();
								}
							}}
						>
							Yes
							{deleteStudentLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
						</button>
					</div>
				</Box>
			</Modal>

			<Modal open={passwordModal} onClose={closePasswordModal} className="student_detail_delete_profile">
				<Box sx={modalStyle}>
					<p className="reversal_accept_text">Are you sure you want to reset password?</p>

					<p className="student_detail_delete_sub_header">New Credentials will be sent to the student on their registered phone number</p>

					<div className="mt-3" style={{ float: "right" }}>
						<button className="btn btn-md student_detail_no" onClick={closePasswordModal}>
							No
						</button>
						<button
							className="btn btn-md student_detail_password_confirm ml-2"
							onClick={() => {
								if (!resetPasswordLoading) {
									resetPassword();
								}
							}}
						>
							Yes
							{resetPasswordLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
						</button>
					</div>
				</Box>
			</Modal>

			<Modal open={contentModal ? true : false} onClose={closeContentModal} className="student_detail_delete_profile">
				<Box sx={modalStyle}>
					<p className="reversal_accept_text">
						{+contentModal === 2 ? (
							"Are you sure you want to unlock this profile?"
						) : (
							<span>
								Are you sure you want to <span style={{ color: "#BC0000" }}>lock</span> this profile?
							</span>
						)}
					</p>

					<p className="student_detail_delete_sub_header">
						{+contentModal === 2
							? "On getting unlocked, student will regain access to their portal"
							: "On getting locked, student will not be able to access any study materials on their portal"}
					</p>

					<div className="mt-3" style={{ float: "right" }}>
						<button className="btn btn-md student_detail_no" onClick={closeContentModal}>
							No
						</button>
						<button
							className="btn btn-md student_detail_password_confirm ml-2"
							onClick={() => {
								if (!lockLoading) {
									toggleLockProfile(+contentModal === 2 ? false : true);
								}
							}}
						>
							Yes
							{lockLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
						</button>
					</div>
				</Box>
			</Modal>

			<div
				className={`student_data_container`}
				style={{
					"--student_card_margin_top": style.marginTop ? style.marginTop : "2rem",
					"--student_card_margin_bottom": style.marginBottom ? style.marginBottom : "2rem",
					cursor: "default",
				}}
			>
				{studentData && studentData.student_data && studentData.student_data.is_tablet_issued && (
					<div className="student_tablet_wrapper">
						<img
							src={IncludesTab}
							alt="includes Tab"
							className={`${
								!studentData.student_data.profile_locked && !studentData.student_data.is_installment_created
									? "student_tablet_img_no_enroll"
									: "student_tablet_img"
							} ${isMobile ? "is-mobile" : ""}`}
						/>
					</div>
				)}
				{studentData && studentData.student_data && (
					<div className={`student_enrolled_wrapper${isMobile ? " is-mobile" : ""}`}>
						{studentData.student_data.is_installment_created ? <div className="student_enrolled">Enrolled</div> : null}
						{studentData.student_data.profile_locked && (
							<div className="d-flex align-items-center">
								<img src={LockcontentImg} style={{ width: 16, marginLeft: 8 }} alt="locked" />
								<div className="student_locked_tag">Content locked</div>
							</div>
						)}
					</div>
				)}

				<div className={"search_student_details" + (isMobile ? " is-mobile" : "")}>
					<div className="search_details_flex align-items-center">
						<img src={LeftArrow} alt="go back" className="cursor-pointer" onClick={goback} />

						<div className="search_student_detail_profile ml-2">
							{studentData && studentData.student_data && generateProfileInitials(studentData.student_data.name)}
						</div>

						<div className="ml-2">
							<div className="student_detail_id_container">
								{studentData && studentData.student_data && studentData.student_data.id && (
									<p className="student_detail_id_text">ID-{studentData.student_data.id}</p>
								)}
							</div>
							<p className="student_detail_name_text">{studentData && studentData.student_data && studentData.student_data.name}</p>
							{studentData && studentData.student_data && studentData.student_data.email && studentData.student_data.email !== "\\N" && (
								<p className="student_detail_email_text">
									{studentData.student_data.email}
									{/* <img
                                        src={SuccessImg}
                                        alt="verified"
                                        className="ml-1"
                                    /> */}
								</p>
							)}
							{true && (
								<div className="mywalletContainer">
									<img src={WalletIcon} alt="" />{" "}
									<div style={{ fontWeight: "500" }}>
										<span style={{ color: "#F47B26" }}>my</span>
										<span style={{ color: "#1F3061" }}>wallet :</span>
									</div>
									<div style={{ fontWeight: "600" }}>
										{currencyFormatter(studentData?.student_data?.wallet_balance ? studentData?.student_data?.wallet_balance : 0)}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="search_student_details_flex phone-number">
						<div>
							<p className="search_student_detail_header">Phone No</p>
							<p className="search_student_detail_value">
								{studentData &&
									studentData.student_data &&
									studentData.student_data.mobile_number &&
									// `*****${studentData.student_data.mobile_number.substring("5")}`
									`${studentData.student_data.mobile_number}`}
								{studentData?.student_data?.is_mobile && (
									<>
										&nbsp;
										<img src={GreenTick} alt="" />
									</>
								)}
								{/* <img
                                    src={SuccessImg}
                                    alt="verified"
                                    className="ml-1"
                                /> */}
							</p>
						</div>
					</div>
					{/* <div className="search_student_details_flex">
                        <div>
                            <p className="search_student_detail_header">
                                Centre
                            </p>
                            <p className="search_student_detail_value">
                                {
                                    studentData &&
                                    studentData.student_data &&
                                    studentData.student_data.school_assoc &&
                                    studentData.student_data.school_assoc.name
                                }
                            </p>
                        </div>
                    </div> */}
					<div className="search_student_details_flex registered-on">
						<div>
							<p className="search_student_detail_header">Registered on</p>
							<p className="search_student_detail_value">
								{studentData &&
								studentData.student_data &&
								studentData.student_data.date_joined &&
								moment(studentData.student_data.date_joined, "YYYY-MM-DD").format("MMM DD, YYYY")
									? moment(studentData.student_data.date_joined, "YYYY-MM-DD").format("MMM DD, YYYY")
									: "-"}
							</p>
						</div>
					</div>
					<div className="search_student_details_content_flex last-paid">
						<div>
							<p className="search_student_detail_header">Last paid on</p>
							<p className="search_student_detail_value">
								{studentData &&
								studentData.student_data &&
								studentData.student_data.last_paid_on &&
								moment(studentData.student_data.last_paid_on, "YYYY-MM-DD").format("MMM DD, YYYY")
									? moment(studentData.student_data.last_paid_on, "YYYY-MM-DD").format("MMM DD, YYYY")
									: "-"}
							</p>
						</div>
						{optionsNeeded && studentData && studentData.student_data && studentData.student_data.profile_locked ? (
							<div className="locked_content_wrapper cursor-pointer" onClick={() => setContentModal(2)}>
								<span className="locked_content_text">Click to unlock</span>
								<img src={LockcontentImg} alt="locked" />
							</div>
						) : optionsNeeded ? (
							<div className="unlocked_content_wrapper cursor-pointer" onClick={() => setContentModal(1)}>
								<span className="unlocked_content_text">Click to lock</span>
								<img src={UnLockcontentImg} alt="unlocked" />
							</div>
						) : null}
					</div>

					<div className="search_student_details_flex options">
						{optionsNeeded && (
							<div>
								<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
									<MoreVertIcon
										style={{
											fontSize: "2.5rem",
											color: "#000000",
										}}
									/>
								</IconButton>
								<Menu
									id="long-menu"
									anchorEl={anchorEl}
									keepMounted
									open={open}
									onClose={handleClose}
									PaperProps={{}}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									style={{
										marginLeft: "1rem",
										marginTop: "-0.8rem",
									}}
								>
									{options.map((option) => (
										<MenuItem
											key={option}
											value={option}
											selected={option === "Pyxis"}
											onClick={() => handleClose(option)}
											style={{
												padding: "1rem 2rem",
											}}
											className="mui_dropdown_options"
										>
											<span
												className={`${
													option === "Reset Password" || option === "Edit Details"
														? "student_detail_option_reset_pw"
														: "student_detail_option_delete"
												}`}
											>
												{option}
											</span>
										</MenuItem>
									))}
								</Menu>
							</div>
						)}
					</div>
				</div>
				{installmentMissed && (
					<div className="student_installment_missed">
						<img src={WarningImg} alt="warning" />
						<span className="student_installment_missed_text">Missed installment due date</span>
					</div>
				)}
			</div>
		</>
	);
};

export default StudentCard;
