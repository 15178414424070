import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Styles from "./feeportal.module.css";
import ReactLoading from "react-loading";
import myclassroom_logo from "../../assets/img/mycrm-logo.svg";
import paymentFailedImg from "./css/payment-failed.gif";

const HostelPaymentLink = (props) => {
	const navigate = useNavigate();

	const { orderid } = useParams();

	// const [username, setUsername] = useState("");
	// const [promocode, setPromocode] = useState("");
	// const [sectionid, setSection] = useState("");
	// const [dataa, setDataa] = useState([]);
	// const [installment_id, setInstallment_id] = useState("");

	const [amount, setAmount] = useState("");
	const [student, setStudent] = useState("");
	const [isloading, setLoading] = useState(false);
	const [order_id, setOrder] = useState("");

	const [razorpayData, setRazorpayData] = useState();

	const [error, setError] = useState("");

	const getRazorpayOrderId = (id, amount, email) => {
		// alert(id)
		let formdata = new FormData();
		formdata.append("payment_id", id);
		formdata.append("amount", amount);
		formdata.append("email", email);
		axios
			.post(`${process.env.REACT_APP_BASE_URL}support/create-hostel-razorpay-order/`, formdata)
			.then((response) => {
				console.log(response.data.payload, "razorpayorderid");
				if (!response.data.status) {
					setError(response.data.message);
				} else if (response.data.status) {
					setRazorpayData(response.data.payload);
				}
				setLoading(false);

				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	useEffect(() => {
		// let order = props.match.params.orderid;
		setLoading(true);
		axios
			.get(process.env.REACT_APP_BASE_URL + "payment/get-order-details/?is_hostel=1&order_id=" + orderid)
			.then((response) => {
				// setDataa(response.data);
				// setUsername(response.data.student_assoc.mobile_number);
				// setSection(response.data.student_assoc.section_assoc.id);
				// setPromocode(response.data.promocode);

				setOrder(response.data.id);
				setStudent(response.data.student_assoc);
				setAmount(response.data.amount_paid);

				getRazorpayOrderId(response.data.id, response.data.amount_paid, response.data.student_assoc.email);
			})
			.catch((err) => {
				console.log(err);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	var text2 = {
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "15px",
		lineHeight: "300.6%",
		/* or 45px */

		color: "#000000",
	};

	var load;
	if (isloading) {
		load = (
			<center>
				<ReactLoading type="cylon" color="#09AEE5" />
			</center>
		);
	}
	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	};

	const displayRazorpay = async (e) => {
		// alert(process.env.REACT_APP_RZP_ID)
		e.preventDefault();
		const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}

		// Getting the order details back
		let amount_obj = amount * 100;
		const options = {
			//key : "rzp_test_xguDglODlGEiy0", //test
			key: process.env.REACT_APP_RZP_ID, // Enter the Key ID generated from the Dashboard
			amount: amount_obj.toString(),
			currency: "INR",
			// name: "Soumya Corp.",
			description: "myclassroom",
			order_id: razorpayData.order_id,
			handler: async function (response) {
				let formdata = new FormData();
				formdata.append("order_id", orderid);
				formdata.append("razor_payment_id", response.razorpay_payment_id);
				formdata.append("type", "Razorpay");
				const result = await axios.post(process.env.REACT_APP_BASE_URL + "support/receive-hostel-payment-support-handle/", formdata);
				console.log(result.data);
				if (result?.data?.Success) {
					alert(result.data.Success);
				}
				// alert("navigate")
				navigate("/payment/payment-success-hostel", {
					state: {
						payment_id: order_id,
						// section_id: dataa.section_assoc.section_assoc.id,
						// school_id: dataa.section_assoc.school_assoc,
						// student_id: dataa.student_assoc.id,
					},
				});
			},
			prefill: {
				name: student.name,
				email: student.email ? student.email : "student@myclassroom.digital",
				contact: "+91" + student.mobile_number,
			},
			theme: {
				color: "#61dafb",
			},
		};
		const paymentObject = new window.Razorpay(options);
		paymentObject.open();
	};

	return (
		<div className="App">
			<nav className={Styles.navbar_custom}>
				<div className="container-fluid">
					<div className="navbar-header">
						<button id={Styles.btn1} type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<img src={myclassroom_logo} width="60%" alt="logo" />
					</div>
					<div className="collapse navbar-collapse" id="myNavbar"></div>
				</div>
			</nav>

			{isloading ? (
				load
			) : error ? (
				<div className="payment_link_error_container">
					<img src={paymentFailedImg} alt="failed" style={{ width: "12rem" }} />
					<p className="payment_link_error_header">{error}</p>
				</div>
			) : (
				<header className="App-header">
					<div className="container">
						<div className="row">
							<div className="col-md-2"></div>
							<div className="col-md-8">
								<div
									style={{
										border: "1px solid rgba(0, 0, 0, 0.4)",
										marginTop: "50px",
										padding: "20px",
										borderRadius: "37px",
										boxSizing: "border-box",
									}}
								>
									<center>
										<div style={{ borderBottom: " 1px solid rgba(0, 0, 0, 0.4)" }}>
											<p
												style={{
													color: "black",
													fontSize: "17px",
													fontWeight: "bold",
												}}
											>
												Payment Summary
											</p>
										</div>
										<form onSubmit={displayRazorpay} style={{ marginTop: "30px" }}>
											<div className="row">
												<div className="col-xs-6">
													<p style={text2}>
														Student Name : <br />
														Student Email : <br />
														Student Mobile : <br />
													</p>
												</div>
												<div className="col-xs-6" style={{ textAlign: "left" }}>
													<p style={text2}>
														{student.name} <br />
														{student.email} <br />
														{student.mobile_number}
														<br />
													</p>
												</div>
											</div>

											<div className="row">
												<div className="col-xs-6">
													<p style={text2}>Net Amount :</p>
												</div>
												<div className="col-xs-6" style={{ textAlign: "left" }}>
													<p style={text2}>
														<div style={{ fontWeight: "bold" }}>&#x20b9; {amount}</div>
													</p>
												</div>
											</div>

											{/* <button id={Styles.btn3} style={{margin:'10px', width:'100px'}} className="App-link" > */}
											{/*     Pay */}
											{/* </button> */}
											<div>
												<button className="btn btn-primary" style={{ backgroundColor: "#EB7926", color: "white" }} type="submit">
													Proceed To Pay
												</button>
											</div>
										</form>
									</center>
								</div>
							</div>

							<div className="col-md-2"></div>
						</div>
					</div>
				</header>
			)}
		</div>
	);
};

export default HostelPaymentLink;
