import React, { useState, useCallback } from "react";
import { Asterisk, currencyFormatter } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { Select, DatePicker } from "antd";
import { Modal, Box } from "@mui/material";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { disableFutureData } from "../../../utils/helperFunctions";

const { Option } = Select;
const programOptions = ["Amazonpay", "Paytm", "Googlepay", "Phonepe", "Instamojo", "Razorpay", "NEFT/IMPS/RTGS", "Others"];

const BankDeposit = ({ isLead = false, paymentAmount, studentName = "", sectionSchoolID, hostel_id, student_id, location, type, studentDetails }) => {
	const navigate = useNavigate();

	const [partnerName, setPartnerName] = useState(null);
	const [urtCode, setUrtCode] = useState(null);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [paymentSlipImage, setPaymentSlipImage] = useState(null);
	const [formError, setFormError] = useState({});
	const [openSumbitModal, setOpenSumbitModal] = useState(false);

	const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
		if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
			console.log(acceptedFiles[0]);
			// Accepted file
			setFormError({ ...formError, paymentSlipImage: null });
			setPaymentSlipImage(acceptedFiles[0]);
		} else {
			if (
				rejectedFiles &&
				rejectedFiles.length > 0 &&
				rejectedFiles[0].errors &&
				rejectedFiles[0].errors.length > 0 &&
				rejectedFiles[0].errors[0].code === "file-too-large"
			) {
				setFormError({
					...formError,
					paymentSlipImage: (
						<span>
							The image size is larger than <strong>10 MB</strong>. Please reduce the image size.
						</span>
					),
				});
			} else {
				setFormError({
					...formError,
					paymentSlipImage: (
						<span>
							Please upload a <strong>.png</strong> or <strong>.jpg</strong> image
						</span>
					),
				});
			}
			// setFormError({ ...formError, paymentSlipImage: null });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { getInputProps, getRootProps } = useDropzone({
		multiple: false,
		maxSize: 10.01 * 1024 * 1024, // 10 MB
		accept: {
			"image/png": [".png"],
			"image/jpeg": [".jpg", ".jpeg"],
		},
		onDrop: handleFileDrop,
	});

	const checkFormHasErrors = () => {
		let flag = false;
		let temp = {};
		if (partnerName === null || partnerName === "") {
			temp = { ...temp, partnerName: "Enter valid partner name" };
			flag = true;
		}
		if (urtCode === null || urtCode === "" || !urtCode.trim()) {
			temp = { ...temp, urtCode: "Enter valid utr name" };
			flag = true;
		}
		if (paymentSlipImage === null) {
			temp = { ...temp, paymentSlipImage: "Please upload payment slip image" };
			flag = true;
		}
		setFormError(temp);
		return flag;
	};

	const [loading, setLoading] = useState(false);

	const makeBankDepositPayment = async () => {
		let formdata = new FormData();
		formdata.append("partner_name", partnerName);
		formdata.append("utr_code", urtCode);
		formdata.append("transfer_date", moment(selectedDate).format("YYYY-MM-DD"));
		formdata.append("deposit_amount", paymentAmount);
		formdata.append("payment_slip", paymentSlipImage);
		formdata.append("payment_for", type);

		if (type === "C") {
			!isLead && formdata.append("section_school_id", sectionSchoolID);
		}

		if (type === "H") {
			formdata.append("hostel_id", hostel_id);
		}

		setLoading(true);

		const data = await Api.doUpload("POST", formdata, `${ApiUrl.BANK_DEPOST}${student_id}`);

		setLoading(false);

		if (data && data.status) {
			if (type === "C") {
				navigate(`/payment/paymentSuccess`, {
					state: {
						isLead: isLead,
						payment_id: data.data.ack_no,
						section_id: location.state.section_id,
						school_id: location.state.school_id,
						student_id: student_id,
						amount: paymentAmount,
					},
				});
			} else if (type === "H") {
				navigate(`/payment/payment-success-hostel`, {
					state: {
						payment_id: data.data.ack_no,
						section_id: studentDetails.student_data.section_assoc.id,
						school_id: studentDetails.student_data.school_assoc.id,
						student_id: student_id,
					},
				});
			}
		} else {
			if (data && data.error_code === 2001) {
				Swal.fire("Error!", data.message, "error");
			} else {
				if (type === "C") {
					navigate(`/payment/paymentFailed`, {
						state: {
							student_id: student_id,
							section_id: location.state.section_id,
							school_id: location.state.school_id,
							school_name: location.state.school_name,
							section_name: location.state.section_name,
						},
						replace: true,
					});
				} else if (type === "H") {
					navigate(`/payment/payment-failed`, {
						state: { student_id: student_id },
					});
				}
			}
		}
	};

	return (
		<>
			<div className="margin_Bottom antd_dropdown student_filter_drop_header">
				<p className="student_filter_drop_header">
					Partner name <Asterisk />
				</p>
				<Select
					style={{ width: "100%" }}
					placeholder="Select partner name"
					onChange={(value) => {
						setFormError({ ...formError, partnerName: null });
						setPartnerName(value || "");
					}}
					value={partnerName || undefined}
					showArrow
					allowClear
					showSearch
				>
					{programOptions.map((options, index) => {
						return (
							<Option key={index} value={options}>
								{options}
							</Option>
						);
					})}
				</Select>
				{formError.partnerName && <ErrorText text={formError.partnerName} />}
			</div>

			<div className="margin_Bottom">
				<p className="student_filter_drop_header">
					UTR Code <Asterisk />
				</p>

				<input
					maxLength="50"
					className={`change_pw_input ${formError.urtCode ? "change_pw_input_error" : ""}`}
					placeholder="Enter utr code"
					value={urtCode || ""}
					onChange={(e) => {
						setFormError({ ...formError, urtCode: null });
						setUrtCode(e.target.value);
					}}
				/>
				{formError.urtCode && <ErrorText text={formError.urtCode} />}
			</div>

			<div className="margin_Bottom">
				<p className="student_filter_drop_header">
					Transfer Date <Asterisk />
				</p>

				<DatePicker
					allowClear={false}
					getPopupContainer={(triggerNode) => {
						return triggerNode.parentNode;
					}}
					className="attendance_date_picker"
					format={"DD/MM/YYYY"}
					value={selectedDate}
					onChange={(date) => {
						setSelectedDate(date);
					}}
					disabledDate={disableFutureData}
				/>
			</div>

			<div className="margin_Bottom">
				<p className="student_filter_drop_header">Deposit Amount:</p>

				<h3>{currencyFormatter(Number(paymentAmount))}</h3>
			</div>

			<div className="margin_Bottom upCheque" {...getRootProps()}>
				<p className="student_filter_drop_header">
					Upload Screenshot of payment slip <Asterisk />
				</p>
				<div className="d-flex btnWrap">
					{/* <button className="camertaBtn"><img src={cameraIcon} /></button> */}
					<div className="upbtn">
						<input className="fileup" {...getInputProps()} type="file" />{" "}
					</div>
				</div>
				{!paymentSlipImage && !formError.paymentSlipImage && (
					<span>
						Please upload .Jpg, .Png files upto 10Mb
						<br />
					</span>
				)}

				{formError.paymentSlipImage && <ErrorText text={formError.paymentSlipImage} />}
			</div>
			{paymentSlipImage && (
				<div className="form-group cheque-m">
					<h5>
						<i className="fa fa-check-double" style={{ color: "#2CAA00" }}></i>
						{paymentSlipImage.name.length > 25 ? `${paymentSlipImage.name.substring(0, 25)}...` : paymentSlipImage.name}
					</h5>
				</div>
			)}

			<div className="margin_Bottom">
				<button
					className={"confirmBtn"}
					onClick={() => {
						if (!checkFormHasErrors() && !loading) {
							setOpenSumbitModal(!openSumbitModal);
						}
					}}
				>
					Confirm
					{loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
				</button>
			</div>

			<Modal open={openSumbitModal}>
				<Box>
					<div className="view_cheque_modal d-column ">
						<span className="bank_deposit_modal_heading">Are you sure?</span>
						<span className="bank_deposit_modal_heading small ">
							Receiving ₹{currencyFormatter(Number(paymentAmount), false)} as bank deposit from {studentName}
						</span>
						<div className="mt-3 d-flex justify-content-flex-end" style={{ float: "left" }}>
							<button
								className="btn btn-md student_detail_no"
								onClick={() => {
									setOpenSumbitModal(!openSumbitModal);
								}}
							>
								No
							</button>
							<button
								className="btn btn-md student_detail_password_confirm ml-2"
								onClick={() => {
									setOpenSumbitModal(!openSumbitModal);
									makeBankDepositPayment();
								}}
							>
								Yes
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default BankDeposit;
