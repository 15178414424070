import React, { useState, useEffect, useCallback, useRef } from "react";
import DownloadIcon from "../../../assets/img/downloadIcon.svg";
import SearchIcon from "../../../assets/img/search.svg";
import PaymentCard from "./paymentCard";
import Filter from "../common/financeFilter";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import Loader from "../../common/Loader";
import FinanceLoader from "../common/FinanceLoader";
import { useSelector, useDispatch } from "react-redux";
import { SCRL_HANDLE_SEARCH_INIT } from "../../../actions/types";
import { getMyclassroomCentre } from "../../../utils/helperFunctions";
import moment from "moment";
import Reversal from "./reversed";
import Empty from "../../../assets/img/empty_student.svg";
import BackTop from "../common/backTop";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { CustomFinanceTooltip } from "../common/handleTooltip";

const searchByItems = [
	"Acknowledgement no.",
	"Payment mode",
	"Payment ID",
	"Phone number",
	"Student name",
	"Student ID",
	"Received by",
	"Course type",
	"State",
	"Bank",
	"Cheque ID",
	"UTR code",
	"Payment partner",
	"Virtual account no.",
	"Loan no.",
	"Linked acknowledgement no.",
];

const PaymentDetails = () => {
	const dispatch = useDispatch();

	const { myclassroomCentre } = useSelector((state) => state.common);

	const [filterLoading, setFilterLoading] = useState(false);

	const [searchText, setSearchText] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [centre, setCentre] = useState([]);
	const [paymentMode, setPaymentMode] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState([]);
	const [dateError, setDateError] = useState("");

	const searchTextRef = useRef(searchText);
	const startDateRef = useRef(startDate);
	const endDateRef = useRef(endDate);
	const centreRef = useRef(centre);
	const paymentModeRef = useRef(paymentMode);
	const paymentStatusRef = useRef(paymentStatus);

	const handleSearchText = (e) => {
		setSearchText(e.target.value);
		searchTextRef.current = e.target.value;
	};

	const getFinancePayment = useCallback(async ({ skip = 0, limit = 10 }) => {
		let payload = {
			skip,
			limit,
		};

		if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
			payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
			payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
		}

		if (searchTextRef && searchTextRef.current) {
			payload.search = searchTextRef.current;
		}

		if (centreRef && centreRef.current.length > 0) {
			payload.centre_ids = centreRef.current;
		}

		if (paymentModeRef && paymentModeRef.current.length > 0) {
			payload.payment_modes = paymentModeRef.current;
		}

		if (paymentStatusRef && paymentStatusRef.current.length > 0) {
			payload.payment_statuses = paymentStatusRef.current;
		}

		const data = await Api.doFetch("POST", payload, ApiUrl.FINANCE_MANAGE_PAYMENT);

		if (data?.status === true) {
			const payload = data.data;

			if (payload) {
				const { data: items, total, skip, limit } = payload;

				return {
					items,
					total,
					skip,
					limit,
				};
			}
		}

		setFilterLoading(false);

		return null;
	}, []);

	const [downloadLoading, setDownloadLoading] = useState(false);

	const downloadPaymentDetatails = async () => {
		if (!filterApplied() || downloadLoading) {
			return;
		}

		let payload = {};

		if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
			payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
			payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
		}

		if (searchTextRef && searchTextRef.current) {
			payload.search = searchTextRef.current;
		}

		if (centreRef && centreRef.current.length > 0) {
			payload.centre_ids = centreRef.current;
		}

		if (paymentModeRef && paymentModeRef.current.length > 0) {
			payload.payment_modes = paymentModeRef.current;
		}

		if (paymentStatusRef && paymentStatusRef.current.length > 0) {
			payload.payment_statuses = paymentStatusRef.current;
		}

		setDownloadLoading(true);

		const data = await Api.doFetch("POST", payload, ApiUrl.FINANCE_MANAGE_PAYMENT);

		setDownloadLoading(false);

		if (data?.status) {
			const workbook = new ExcelJS.Workbook();

			const sheet = workbook.addWorksheet("Payment details");

			sheet.columns = [
				{ header: "Type_of_Payment", key: "Type_of_Payment" },
				{ header: "Ack No", key: "Ack No" },
				{ header: "Student name", key: "Student name" },
				{ header: "Student ID", key: "Student ID" },
				{ header: "Centre", key: "Centre" },
				{ header: "Academic year", key: "Academic year" },
				{ header: "Student state", key: "Student state" },
				{ header: "Payment date", key: "Payment date" },
				{ header: "Payment mode", key: "Payment mode" },
				{ header: "Payment ID", key: "Payment ID" },
				{ header: "Amount", key: "Amount" },
				{ header: "Phone number", key: "Phone number" },
				{ header: "Email", key: "Email" },
				{ header: "Cheque ID", key: "Cheque ID" },
				{ header: "Cheque date", key: "Cheque date" },
				{ header: "Cheque Bank Name", key: "Cheque Bank Name" },
				{ header: "Payment partner", key: "Payment partner" },
				{ header: "UTR code", key: "UTR code" },
				{ header: "Loan Number", key: "Loan Number" },
				{ header: "Receiving Date", key: "Receiving Date" },
				{ header: "Finance Charges", key: "Finance Charges" },
				{ header: "Virtual Account No.", key: "Virtual Account No." },
				{ header: "City", key: "City" },
				{ header: "Batch", key: "Batch" },
				{ header: "Course", key: "Course" },
				{ header: "Student status", key: "Student status" },
				{ header: "Reversal initiated date", key: "Reversal initiated date" },
				{ header: "Reversal action date", key: "Reversal action date" },
				{ header: "Linked ack no.", key: "Linked ack no." },
				{ header: "Payment status", key: "Payment status" },
				{ header: "Remarks", key: "Remarks" },
			];

			data?.data?.data.forEach((row) => {
				let obj = {
					Type_of_Payment:
						row?.payment_type === "C"
							? row?.payment_for === "advance"
								? "Advance Fee"
								: "Course Fee"
							: row?.payment_type === "H"
							? "Hostel Fee"
							: "",
					"Ack No": row?.is_society ? `S-${row?.ack_no}` : row?.ack_no || "",
					"Student name": row?.student_name || "",
					"Student ID": row?.student_id || "",
					Centre: row?.centre_name || "",
					"Academic year": row?.academic_year || "",
					"Student state": row?.student_state || "",
					"Payment date": row?.date ? moment(row?.date).format("DD/MM/YYYY") : "",
					"Payment mode": row?.payment_mode || "",
					"Payment ID": row?.id || "",
					Amount: row?.amount || "",
					"Phone number": row?.student_mobile_number || "",
					Email: row?.email || "",
					"Cheque ID": row?.cheque_id || "",
					"Cheque date": row?.cheque_date ? moment(row?.cheque_date).format("DD/MM/YYYY") : "",
					"Payment partner": row?.partner_name || "",
					"Cheque Bank Name": row?.bank_name || "",
					"UTR code": row?.utr_code || "",
					"Loan Number": row?.loan_number || "",
					"Receiving Date": row?.receiving_date ? moment(row?.receiving_date).format("DD/MM/YYYY") : "",
					"Finance Charges": row?.financeCharges || "",
					"Virtual Account No.": row?.virtual_account || "",
					City: row?.city || "",
					Batch: row?.batch || "",
					Course: row?.course || "",
					"Student status": row?.student_status ? "Active" : "In-active" || "",
					"Reversal initiated date": row?.reversal_initiated_date ? moment(row?.reversal_initiated_date).format("DD/MM/YYYY") : "",
					"Reversal action date": row?.reversal_action_date ? moment(row?.reversal_action_date).format("DD/MM/YYYY") : "",
					"Linked ack no.": row?.reversal_receipt_id || "",
					"Payment status": row?.payment_status || "",
					Remarks: row?.remarks || "",
				};

				sheet.addRow(obj);
			});

			workbook.xlsx.writeBuffer().then(function (buffer) {
				const blob = new Blob([buffer], { type: "application/xlsx" });
				saveAs(blob, "Payment Details.xlsx");
			});
		} else {
			Swal.fire("Error!", data?.message || "Internal Server Error", "error");
		}
	};

	const { skip, limit } = useSelector((state) => state.scrollingList);

	const filterApplied = () => {
		if (
			searchTextRef.current ||
			startDateRef.current ||
			endDateRef.current ||
			(centreRef.current && centreRef.current.length > 0) ||
			(paymentModeRef.current && paymentModeRef.current.length > 0) ||
			(paymentStatusRef.current && paymentStatusRef.current.length > 0)
		) {
			return true;
		}

		return false;
	};

	const getPaymentDetails = async () => {
		if (moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
			setDateError("From date cannot be greater than To date");
			return;
		}

		setFilterLoading(true);
		const data = await getFinancePayment({
			skip,
			limit,
		});

		dispatch({
			type: SCRL_HANDLE_SEARCH_INIT,
			payload: {
				list: (data && data.items) || [],
				skip: 0,
				total: (data && data.total) || 0,
				scrollLoading: false,
			},
		});

		setFilterLoading(false);
	};

	const [scrollContainer, { loading, scrollLoading, list: paymentList, handleScroll }] = useScrollingList({
		listKey: "finance-payment",
		getData: getFinancePayment,
	});

	useEffect(() => {
		if (myclassroomCentre && myclassroomCentre.length === 0) {
			getMyclassroomCentre();
		}
	}, []);

	const [showReversal, setShowReversal] = useState("");

	const getReversedPayment = (id) => {
		setShowReversal(id);
	};

	if (loading) {
		return <FinanceLoader text="Loading payment details..." />;
	}

	return (
		<div className="course_list_container">
			{showReversal && <Reversal open={showReversal ? true : false} close={() => setShowReversal(false)} reversalID={showReversal} />}

			<div ref={scrollContainer} className="pending-cheques-scroll-container" onScroll={handleScroll}>
				<div className="course_list_header payment_details">
					<span className="pending-cheques__heading_text mb-0 padding_left ">Payment Details</span>

					<div className="d-flex sub_row">
						<div className="d-flex align-items-center payment_details_search_wrapper">
							<img src={SearchIcon} alt="search" className="course_search_image" />
							<CustomFinanceTooltip
								disableFocusListener
								enterTouchDelay={0}
								placement="top-start"
								title={
									searchText && searchText.length > 0 ? (
										""
									) : (
										<div style={{ width: 259 }}>
											<div style={{ fontWeight: 600, fontSize: 14 }}>Search by</div>
											{searchByItems.map((searchBy, searchByIndex) => (
												<div key={searchByIndex} style={{ fontSize: 12, fontWeight: 400 }}>
													{searchBy}
												</div>
											))}
										</div>
									)
								}
							>
								<input className="course_search_input" value={searchText} onChange={handleSearchText} placeholder="Search a payment" />
							</CustomFinanceTooltip>
						</div>
						<button
							className={
								searchText && searchText.trim().length > 0 && !filterLoading
									? "btn btn-md payment_details_search_button "
									: "btn btn-md payment_details_search_button disabled"
							}
							onClick={() => {
								if (searchText && searchText.trim().length > 0 && !filterLoading) {
									getPaymentDetails();
								}
							}}
						>
							Search
						</button>
					</div>
				</div>

				<Filter
					startDate={startDate}
					endDate={endDate}
					centre={centre}
					paymentMode={paymentMode}
					paymentStatus={paymentStatus}
					setStartDate={setStartDate}
					startDateRef={startDateRef}
					setEndDate={setEndDate}
					endDateRef={endDateRef}
					setCentre={setCentre}
					setPaymentMode={setPaymentMode}
					setPaymentStatus={setPaymentStatus}
					centreRef={centreRef}
					paymentModeRef={paymentModeRef}
					paymentStatusRef={paymentStatusRef}
					dateError={dateError}
					setDateError={setDateError}
					getDetails={getPaymentDetails}
					filterLoading={filterLoading}
					statusFilter={true}
				/>

				<div className="d-flex export_row">
					<CustomFinanceTooltip title="Apply filters to export the data" placement="left" hideTooltip={filterApplied()}>
						<span className={filterApplied() ? "cursor-pointer" : "disabled-content"} onClick={downloadPaymentDetatails}>
							{downloadLoading ? (
								<span className="spinner spinner-white mr-3" />
							) : (
								<img src={DownloadIcon} alt="Download" className="export_download" />
							)}{" "}
							Export
						</span>
					</CustomFinanceTooltip>
				</div>

				{filterLoading ? (
					<div className="loader_scroll_container">
						<Loader />
					</div>
				) : paymentList?.length === 0 ? (
					<div className="student_search_empty_container mt-5">
						<div className="student_search_empty_img_container">
							<img src={Empty} alt="not found" className="student_search_empty_img" />
						</div>

						<p className="student_search_empty_header">No Payment details found</p>
						<span className="student_search_empty_label">Try adjusting your search to find</span>
						<span className="student_search_empty_label">the best results</span>
					</div>
				) : (
					paymentList.map((data, index) => {
						console.log(data);
						return (
							<PaymentCard
								key={index}
								ackNo={data.ack_no}
								date={data.date}
								paymentType={data.payment_type}
								paymentMode={data.payment_mode}
								paymentStatus={data.payment_status}
								paymentID={data.id}
								studentID={data.student_id}
								centre={data.centre_name}
								studentName={data.student_name}
								mobileNumber={data.student_mobile_number}
								studentStatus={data.student_status}
								amount={data.amount}
								recievedBy={data.received_by}
								courseType={data.course_type}
								studentState={data.student_state}
								linkedAckNo={data.reversal_receipt_id}
								reversedOn={data.reversal_action_date}
								reversedBy={data.reversed_by}
								paymentReversed={data.reversed}
								UtrCode={data.utr_code}
								paymentPartner={data.partner_name}
								VirtualAccountNo={data.virtual_account}
								getReversedPayment={getReversedPayment}
								bankName={data.bank_name}
								ChequePhoto={data.cheque_file}
								LoanNumber={data.loan_number}
								chequeID={data.cheque_id}
								financeCharges={data.financeCharges}
								paymentFor={data.payment_for}
							/>
						);
					})
				)}

				{!loading && scrollLoading && (
					<div className="loader_scroll_container">
						<Loader />
					</div>
				)}

				<BackTop target="pending-cheques-scroll-container" />
			</div>
		</div>
	);
};

export default PaymentDetails;
