import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Accordian from "../../../utils/accordian";
import ChequeModal from "../common/ViewChequeModal";

import moment from "moment";
import Paid from "../../../assets/img/Paid.svg";
import Reversed from "../../../assets/img/Reversed.svg";
import Pending from "../../../assets/img/Pending.svg";
import Failed from "../../../assets/img/Failed.svg";
import ReversePayment from "../../../assets/img/ReversePayment.svg";
import CashDrop from "./CardMoreDetails/Cash-ICash-Reversal-Drop";
import ChequeDrop from "./CardMoreDetails/Cheque-Cheque-Reversal-Drop";
import PosOnlineDrop from "./CardMoreDetails/Pos-Online-Drop";
import BankTransfer from "./CardMoreDetails/Bank-Reversal-Drop";
import NoCostEMI from "./CardMoreDetails/Nocostemi-Reversal-Drop";
import VirtualDrop from "./CardMoreDetails/Virtual-Reversal-Drop";
import Tooltip from "../../common/tooltip";

import { PaymentStatus, getPaymentStatus, getPaymentBucket, PaymentMode, currencyFormatter } from "../../../utils/helperFunctions";

const PaymentCard = (props) => {
	const {
		ackNo = "",
		date = "",
		paymentType = "",
		paymentMode = "",
		paymentStatus = "",
		paymentID = "",
		studentID = "",
		centre = "",
		studentName = "",
		studentStatus = "",
		mobileNumber = "",
		amount = "",
		linkedAckNo = "",
		paymentReversed = "",
		getReversedPayment,
		defaultOpen = false,
		ChequePhoto = "",
		financeCharges = "",
		paymentFor = "",
	} = props;

	const [showMore, setShowMore] = useState(false);
	const [viewChequeModal, setViewChequeModal] = useState(false);

	const handleViewButton = () => {
		setShowMore(!showMore);
	};
	const handleViewCheque = () => {
		setViewChequeModal((state) => !state);
	};

	const viewReceipt = (type, paymentId) => {
		if (type === "C") {
			window.open(`/payment/paymentReceiptSummary/${paymentId}`, "_blank");
		} else {
			window.open(`/payment/hostel-receipt/${paymentId}`, "_blank");
		}
	};

	return (
		<div className={`${linkedAckNo ? "payment_details_card_ack" : "payment_details_card"}`}>
			<div className="sticky_tag d-flex">
				{paymentReversed && <img className="cursor-pointer" alt="reverse payment" src={ReversePayment} />}
				<img
					alt="payment status"
					src={
						getPaymentStatus(paymentStatus) === PaymentStatus.PAID
							? Paid
							: getPaymentStatus(paymentStatus) === PaymentStatus.FAILED
							? Failed
							: getPaymentStatus(paymentStatus) === PaymentStatus.REVERSED
							? Reversed
							: Pending
					}
				/>
			</div>

			{linkedAckNo && (
				<div className="payment_details_view_button header" onClick={() => getReversedPayment(ackNo)}>
					Linked Ack No: {linkedAckNo}
				</div>
			)}

			<div className="payment_details_card_row">
				<div className="payment_details_card_group1 paymanet_details_card_divider00 paymanet_details_card_divider0">
					<div className="paymanet_details_card_divider00">
						<div
							className="payment_details_circular_img"
							style={{
								"--finance-payment-type": paymentType === "C" ? (paymentFor === "advance" ? "#D7F9DA" : "#F9D7E5") : "#FDF39D",
							}}
						>
							{paymentFor === "advance" ? (paymentType === "C" ? "A" : paymentType) : paymentType}
						</div>
					</div>

					<div className="paymanet_details_card_divider0 d-flex">
						<div>
							<div className="payment_details_list_card_header">Ack No.</div>
							<div className="d-flex" style={{ gap: "10px", alignItems: "center" }}>
								<p className="payment_details_list_card_header value">{ackNo}</p>
								{getPaymentStatus(paymentStatus) !== PaymentStatus.FAILED && (
									<p
										className="payment_details_list_card_header underlined"
										onClick={() => {
											viewReceipt(paymentType, linkedAckNo ? linkedAckNo : ackNo);
										}}
									>
										View receipt
									</p>
								)}
							</div>
							<div className="payment_details_list_card_header subvalue">Date: {moment(date).format("MMM DD, YYYY")}</div>
						</div>
					</div>
				</div>

				<div className="paymanet_details_card_divider1 d-flex justify-content-between">
					<div>
						<div className="payment_details_list_card_header">Payment mode</div>
						<div className="d-flex" style={{ gap: "10px", alignItems: "center" }}>
							<p className="payment_details_list_card_header value">{paymentMode}</p>
							{getPaymentBucket(paymentMode) === PaymentMode.CHEQUE && (
								<p onClick={handleViewCheque} className="payment_details_list_card_header underlined">
									View cheque
								</p>
							)}
						</div>
						<div className="payment_details_list_card_header subvalue">ID: {paymentID === null ? "Not Applicable!" : paymentID}</div>
					</div>
					<div className="paymanet_details_card_divider2">
						<div className="payment_details_list_card_header">Student ID</div>
						<p className="payment_details_list_card_header value">{studentID}</p>
						<div className="payment_details_list_card_header subvalue mycrm_nowrap">
							Centre:{" "}
							{!centre ? (
								"-"
							) : centre.length < 13 ? (
								centre
							) : (
								<Tooltip text={centre}>
									<span>{centre.substring(0, 12) + "..."}</span>
								</Tooltip>
							)}
						</div>
					</div>
				</div>

				<div className="paymanet_details_card_divider3 d-flex">
					<div className={`payment_details_student_circular_img ${!studentStatus ? "inactive" : ""}`}></div>

					<div>
						<div className="payment_details_list_card_header">Student Name</div>
						<p className="payment_details_list_card_header value">{studentName}</p>
						<div className="payment_details_list_card_header subvalue">Phone No: +91 {mobileNumber}</div>
					</div>
				</div>

				<div className="paymanet_details_card_divider5 d-flex">
					<div className="paymanet_details_card_amount">
						<p
							className={`payment_details_list_card_header value big ${
								getPaymentStatus(paymentStatus) === PaymentStatus.REVERSED ? "reversed" : ""
							}`}
						>
							₹ {currencyFormatter(amount, false)}
						</p>
						{getPaymentBucket(paymentMode) === PaymentMode.NO_COST_EMI && (
							<p className={`finance_charge_payment_detail`}>Finance charge: ₹ {currencyFormatter(financeCharges, false)}</p>
						)}
					</div>

					{!defaultOpen && (
						<div onClick={handleViewButton} className="payment_details_view_button">
							<span>{showMore ? "Hide details" : "View details"}</span>
							{showMore ? (
								<KeyboardArrowUpIcon style={{ width: "22px", height: "22px" }} />
							) : (
								<KeyboardArrowDownIcon style={{ width: "22px", height: "22px" }} />
							)}
						</div>
					)}
				</div>
			</div>

			<Accordian expand={defaultOpen ? true : showMore} noStyles={true}>
				{
					getPaymentBucket(paymentMode) === PaymentMode.CASH ? (
						<CashDrop {...props} />
					) : getPaymentBucket(paymentMode) === PaymentMode.CHEQUE ? (
						<ChequeDrop {...props} />
					) : getPaymentBucket(paymentMode) === PaymentMode.BANK_TRANSFER ? (
						<BankTransfer {...props} />
					) : getPaymentBucket(paymentMode) === PaymentMode.ONLINE || getPaymentBucket(paymentMode) === PaymentMode.POS ? (
						<PosOnlineDrop {...props} />
					) : getPaymentBucket(paymentMode) === PaymentMode.NO_COST_EMI ? (
						<NoCostEMI {...props} />
					) : getPaymentBucket(paymentMode) === PaymentMode.VIRTUAL_ACCOUNT ? (
						<VirtualDrop {...props} />
					) : null
					// {paymentMode === ('Cheque reversal' || 'cheque reversal') && <ChequeDrop paymentMode={paymentMode} chequeID={1234} entryDate={date} bankName={"Punjab national bank"} courseType={courseType} studentState={studentState} amount={amount} handleViewButton={handleViewButton} showMore={showMore} reversedBy={reversedBy} reversedOn={reversedOn} />}
					// {paymentMode === ('Cheque' || 'cheque') && <ChequeDrop paymentMode={paymentMode} chequeID={1234} entryDate={date} bankName={"Punjab national bank of india"} courseType={courseType} studentState={studentState} amount={amount} handleViewButton={handleViewButton} showMore={showMore} />}
					// {(paymentMode === ('Pos' || 'pos') || paymentMode === ('Online' || 'online') || paymentMode === ('Razorpay' || 'razorpay')) && <PosDrop courseType={courseType} studentState={studentState} amount={amount} handleViewButton={handleViewButton} showMore={showMore} />}
					// {(paymentMode === ('Bank transfer' || 'bank transfer') || paymentMode === ('Bank reversal' || 'bank reversal')) && <BankDrop paymentMode={paymentMode} courseType={courseType} studentState={studentState} amount={amount} handleViewButton={handleViewButton} showMore={showMore} UtrCode={UtrCode} paymentPartner={paymentPartner} reversedBy={reversedBy} reversedOn={reversedOn} />}
					// {(paymentMode === ('UPI' || 'upi') || paymentMode === ('UPI reversal' || 'upi reversal')) && <UPIDrop paymentMode={paymentMode} courseType={courseType} studentState={studentState} amount={amount} handleViewButton={handleViewButton} showMore={showMore} UtrCode={UtrCode} paymentPartner={paymentPartner} reversedBy={reversedBy} reversedOn={reversedOn} />}
					// {(paymentMode === ('Virtual account' || 'virtual account') || paymentMode === ('Virtual account reversal' || 'virtual account reversal')) && <VirtualDrop paymentMode={paymentMode} paycourseType={courseType} studentState={studentState} amount={amount} handleViewButton={handleViewButton} showMore={showMore} VirtualAccountNo={VirtualAccountNo} paymentPartner={paymentPartner} reversedBy={reversedBy} reversedOn={reversedOn} courseType={courseType} />}
				}
			</Accordian>

			<ChequeModal
				handleViewCheque={handleViewCheque}
				name={studentName}
				date={date}
				mobileNumber={mobileNumber}
				ChequePhoto={ChequePhoto}
				open={viewChequeModal}
			/>
		</div>
	);
};

export default PaymentCard;
