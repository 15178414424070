import React,{useState, useEffect} from "react";
import Flag from "../../../assets/img/flag.svg";
import Loader from "../../common/Loader";
import { currencyFormatter, getPaymentMode } from "../../../utils/helperFunctions";
import Options from "../options";
import Swal from "sweetalert2";
import Reversal from "./reversal";
import Empty from "../../../assets/img/empty_history.svg";
import AccordionComponent from "../../../utils/accordian";

import ArrowDown from "../../../assets/img/arrow_down.svg";
import ArrowUp from "../../../assets/img/arrow_up.svg";

const PaymentHistoryCard = ({
    currentTab,
    loading,
    setLoading,
    historyList,
    refresh,
    downloadInvoice,
    setReversalID,
    reversalID,
    reversalDeclined,
    setReversalDeclined,
    canViewReceipt,
    canDoReversal,
    checkCashConfirmed,
    isCourseActive
}) => {

    function convertDate(inputFormat) {
        function pad(s) {
            return s < 10 ? "0" + s : s;
        }
        var d = new Date(inputFormat);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
    }

    const [toggleArray, settoggleArray] = useState([]);

    const handleToggleClick = (index) =>{
        settoggleArray(toggleArray.map((value, i)=> {
            if (i=== index){
                return !value;
            }
            return value;
        })) 
    }

    const handleToggleReversalReason = (paymentHistoryId) => () => {
        if (reversalDeclined !== paymentHistoryId) {
            setReversalDeclined(paymentHistoryId)
        } else {
            setReversalDeclined("");
        }
    }

    useEffect(() => {
        settoggleArray(historyList.map(item=>{
            return false
        }))
    }, [historyList])

    return (
        <>
            {
                loading ?
                <div className="loader_container">
                    <Loader />
                </div>
                :
                <div className="history-card-container">
                    {
                        historyList.length > 0 &&
                        historyList.map((paymentHistory, i) => {
                            return (
								<React.Fragment key={`row${i}`}>
									<div className="accordion-container-main">
										<div className="accordion-container">
											<div className="acc-inside-header">
												<div className="paid-on">
													<div className="paid-on-small">Paid On:</div>
													<div className="paid-on-big">{convertDate(paymentHistory.activation_time)}</div>
												</div>
												<div className="paid-on">
													<div className="paid-on-small">Amount:</div>
													<div className="paid-on-big">{currencyFormatter(paymentHistory.amount_paid)}</div>
												</div>
												<div className="paid-on">
													<div className="paid-on-small">Status:</div>
													<div className="paid-on-big">
														{paymentHistory.payment_type === "mywallet"
															? paymentHistory.status === "Paid" && <span className="bg-redeemed">Redeemed</span>
															: paymentHistory.status === "Paid" && (
																	<span className="bg-success">{paymentHistory.status} </span>
															  )}
														{paymentHistory.status === "Processing" && <span className="bg-info">{paymentHistory.status}</span>}
														{(paymentHistory.status === "Failed" || paymentHistory.status === "Failure") && (
															<span className="bg-danger">{paymentHistory.status}</span>
														)}
														{paymentHistory.status === "Pending" && <span className="bg-info">{paymentHistory.status}</span>}
														{paymentHistory.status === "Reversed" && <span className="bg-info">{paymentHistory.status}</span>}
														{paymentHistory.is_reversal_refund_init && (
															<img src={Flag} alt="flag" className="reversal_raised_flag" />
														)}
													</div>
												</div>
												{paymentHistory.payment_type !== "mywallet" ? (
													<div className="paid-on">
														{(canViewReceipt(paymentHistory.status, paymentHistory.payment_type) ||
															paymentHistory.status === "Paid") && (
															<Options
																openReceipt={() => downloadInvoice(paymentHistory.id)}
																setReversal={() => {
																	if (canDoReversal(paymentHistory.payment_type, paymentHistory.cash_confirmed)) {
																		setReversalID(paymentHistory.id);
																		if (!toggleArray[i]) {
																			handleToggleClick(i);
																		}
																	} else if (checkCashConfirmed(paymentHistory.cash_confirmed)) {
																		Swal.fire(
																			"",
																			`Cash entry already confirmed by ${paymentHistory.accountant_name} (Accountant), can’t be reversed`,
																			"warning"
																		);
																	} else {
																		Swal.fire(
																			"",
																			"Reversal can only be initiated where mode of payment is Cash/Cheque/offline swipe POS/Bank Transfer (UPI)",
																			"warning"
																		);
																	}
																}}
																reversalPending={paymentHistory.is_reversal_refund_init}
																canViewReceipt={canViewReceipt(paymentHistory.status, paymentHistory.payment_type)}
																canDoReversal={
																	paymentHistory.status === "Paid" && currentTab !== "Hostel" && isCourseActive
																		? true
																		: false
																}
															/>
														)}
													</div>
												) : (
													<div className="paid-on"></div>
												)}
											</div>
											<button type="button" className="payment_reversal_collapse_button" onClick={() => handleToggleClick(i)}>
												<img src={toggleArray[i] ? ArrowUp : ArrowDown} alt="arrow" style={{ width: "1.2rem" }} />
											</button>
										</div>

										<AccordionComponent
											expand={toggleArray[i]}
											hideSummary={true}
											accordionDetailsClasses={{ root: "accordion-details-hide" }}
										>
											<div className="accordion-inside">
												<div className="paid-on-inside">
													<div className="paid-on-small">Mode:</div>
													<div className="paid-on-big">{getPaymentMode(paymentHistory.payment_type)}</div>
												</div>
												<div className="paid-on-inside">
													<div className="paid-on-small">Ack No.:</div>
													<div className="paid-on-big">
														{paymentHistory.payment_type !== "mywallet" ? paymentHistory.id : paymentHistory.ack_no}
													</div>
												</div>
											</div>
											<div className="accordion-inside-2">
												{paymentHistory.id === reversalID ? (
													<div>
														<div className="reversal_td_container">
															<Reversal
																reversalID={reversalID}
																refresh={(reload) => {
																	setReversalID("");
																	if (reload) {
																		setLoading(true);
																		refresh();
																	}
																}}
																mobile={true}
															/>
														</div>
													</div>
												) : paymentHistory.is_reversal_refund_init ? (
													<div>
														<div className="reversal_td_container">
															<div className="reversal_reason_container_flag">
																<img src={Flag} alt="flag" className="reversal_raised_flag mr-1" />
																<span className="reversal_raised_text">
																	Reversal initiated for the payment, waiting on Centre Head for approval.
																</span>
															</div>
														</div>
													</div>
												) : paymentHistory.reversal_declined ? (
													<tr>
														<td colSpan={"6"} className="reversal_td_container">
															<div className="reversal_reason_container_flag">
																<div className="reversal_raised_text reversal_declined">
																	<div
																		className={`reversal_declined_content${
																			reversalDeclined === paymentHistory.id ? " open" : ""
																		}`}
																	>
																		<div className="reversal_declined_tag">Reason to decline</div>

																		<div className="reversal_declined_remark">
																			{paymentHistory.reversal_declined_remarks}
																		</div>

																		<div className="reversal_declined_centre_head">
																			Declined by {paymentHistory.reversal_declined_by} (Centre Head)
																		</div>
																	</div>

																	<span
																		className="reversal_declined_toggle"
																		onClick={handleToggleReversalReason(paymentHistory.id)}
																	>
																		{reversalDeclined === paymentHistory.id ? (
																			"Hide reason"
																		) : (
																			<>
																				<span style={{ color: "#AF0000" }}>Reversal request declined, </span>
																				<span>click to know reason</span>
																			</>
																		)}
																	</span>
																</div>
															</div>
														</td>
													</tr>
												) : null}
											</div>
										</AccordionComponent>
									</div>
								</React.Fragment>
							);
                        })
                    }

                    {
                        historyList.length === 0 &&
                        <div className="student_search_empty_container">
                            <div className="payment_history_empty_container">
                                <img
                                    src={Empty}
                                    alt="not found"
                                    className="student_search_empty_img"
                                />
                            </div>

                            <p className="student_search_empty_header">
                                No payment made yet.
                            </p>
                        </div>
                    }
                    
                </div>
            }
        </>
    )
};

export default PaymentHistoryCard;