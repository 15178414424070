import React, { useState } from "react";
import Accordian from "../../../utils/accordian";
import Paid from "../../../assets/img/Paid.svg";
import { useNavigate } from "react-router-dom";

import { currencyFormatter } from "../../../utils/helperFunctions";
import Tooltip from "../../common/tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import ErrorImg from "../../../assets/img/error_txt.svg";

const RedeemedCard = ({ payment }) => {
	const navigate = useNavigate();

	const studentStatus = true;

	const defaultOpen = false;

	const [showMore, setShowMore] = useState(false);

	const handleViewButton = () => {
		setShowMore(!showMore);
	};
	return (
		<>
			<div className="payment_details_card_ack">
				<div className="sticky_tag d-flex">
					<img
						alt="payment status"
						// src={
						// 	getPaymentStatus(paymentStatus) === PaymentStatus.PAID
						// 		? Paid
						// 		: getPaymentStatus(paymentStatus) === PaymentStatus.FAILED
						// 		? Failed
						// 		: getPaymentStatus(paymentStatus) === PaymentStatus.REVERSED
						// 		? Reversed
						// 		: Pending
						// }
						src={Paid}
					/>
				</div>

				<div className="payment_details_card_row">
					<div className="payment_details_card_group1 paymanet_details_card_divider00 paymanet_details_card_divider0">
						<div className="paymanet_details_card_divider00">
							<div
								className="payment_details_circular_img"
								style={{
									"--finance-payment-type": payment?.payment_for === "course" ? "#F9D7E5" : "#FDF39D",
								}}
							>
								{payment?.payment_for === "course" ? "C" : "H"}
							</div>
						</div>

						<div className="paymanet_details_card_divider0 d-flex">
							<div>
								<div className="payment_details_list_card_header">Txn No.</div>
								<div className="d-flex" style={{ gap: "10px", alignItems: "center" }}>
									<p className="payment_details_list_card_header value">{payment?.ack_no}</p>
								</div>
								<div className="payment_details_list_card_header subvalue">Date: {moment(payment?.created_on).format("MMM DD, YYYY")}</div>
							</div>
						</div>
					</div>
					<div className="paymanet_details_card_divider1 d-flex justify-content-between">
						<div>
							<div className="payment_details_list_card_header">Payment mode</div>
							<div className="d-flex" style={{ gap: "10px", alignItems: "center" }}>
								<p className="payment_details_list_card_header value">
									<span style={{ color: "#F47B26" }}>my</span>
									<span style={{ color: "#1F3061" }}>wallet</span>
								</p>

								<p
									className="payment_details_list_card_header underlined"
									onClick={() => navigate(`/mywallet-history/${payment?.student_id}`)}
								>
									View history
								</p>
							</div>
							<div className="payment_details_list_card_header subvalue">ID: {payment?.payment_id}</div>
						</div>
						<div className="paymanet_details_card_divider2">
							<div className="payment_details_list_card_header">Student ID</div>
							<p className="payment_details_list_card_header value">{payment?.student_id}</p>
							<div className="payment_details_list_card_header subvalue mycrm_nowrap">
								Centre:{" "}
								{!payment?.centre_name ? (
									"-"
								) : payment?.centre_name.length < 13 ? (
									payment?.centre_name
								) : (
									<Tooltip text={payment?.centre_name}>
										<span>{payment?.centre_name.substring(0, 12) + "..."}</span>
									</Tooltip>
								)}
							</div>
						</div>
					</div>

					<div className="paymanet_details_card_divider3 d-flex">
						<div className={`payment_details_student_circular_img ${!studentStatus ? "inactive" : ""}`}></div>

						<div>
							<div className="payment_details_list_card_header">Student Name</div>
							<p className="payment_details_list_card_header value">{payment?.student_name}</p>
							<div className="payment_details_list_card_header subvalue">Phone No: +91 {payment?.mobile_number}</div>
						</div>
					</div>

					<div className="paymanet_details_card_divider5 d-flex">
						<div className="paymanet_details_card_amount">
							<p
								// className={`payment_details_list_card_header value big ${
								// 	getPaymentStatus(paymentStatus) === PaymentStatus.REVERSED ? "reversed" : ""
								// }`}
								className={`payment_details_list_card_header value big`}
							>
								₹ {currencyFormatter(payment?.amount_paid, false)}
							</p>
						</div>

						{!defaultOpen && (
							<div onClick={handleViewButton} className="payment_details_view_button">
								<span>{showMore ? "Hide details" : "View details"}</span>
								{showMore ? (
									<KeyboardArrowUpIcon style={{ width: "22px", height: "22px" }} />
								) : (
									<KeyboardArrowDownIcon style={{ width: "22px", height: "22px" }} />
								)}
							</div>
						)}
					</div>
				</div>

				<Accordian expand={defaultOpen ? true : showMore} noStyles={true}>
					<div className="payment_details_card_row add_course_division">
						<div className="payment_details_card_group1 paymanet_details_card_divider00 paymanet_details_card_divider0">
							<div className="paymanet_details_card_divider00 d-flex"></div>
							<div className="paymanet_details_card_divider0 d-flex"></div>
						</div>

						<div className="paymanet_details_card_divider1 d-flex justify-content-between">
							<div>
								{!false && (
									<>
										<div className="payment_details_list_card_header">Redeemed by</div>
										<p className="payment_details_list_card_header value">{payment?.redeemed_by}</p>

										<div className="payment_details_list_card_header subvalue">
											Date: {moment(payment?.created_on).format("MMM DD, YYYY")}
										</div>
									</>
								)}
							</div>
							<div className="paymanet_details_card_divider2">
								<div className="payment_details_list_card_header">Course type</div>
								<p className="payment_details_list_card_header value">Classroom (Hybrid)</p>
							</div>
						</div>

						<div className="paymanet_details_card_divider3 d-flex ">
							<div className="payment_details_student_circular_img block"></div>
							<div>
								<div className="payment_details_list_card_header">Student state</div>
								<p className="payment_details_list_card_header value">{payment?.student_state}</p>
							</div>
						</div>

						<div className="paymanet_details_card_divider5 d-flex justify-content-flex-end"></div>
					</div>
				</Accordian>
				<div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginTop: 10 }}>
					{payment?.centre_changed ? (
						payment?.course_changed ? (
							<>
								<img src={ErrorImg} alt="error" className="error_img" style={{ fontSize: 16 }} />
								<span className="error_text" style={{ fontWeight: 500 }}>
									There may be centre and course type changed for student
								</span>
							</>
						) : (
							<>
								<img src={ErrorImg} alt="error" className="error_img" style={{ fontSize: 16 }} />
								<span className="error_text" style={{ fontWeight: 500 }}>
									There may be centre changed for student
								</span>
							</>
						)
					) : (
						payment?.course_changed && (
							<>
								<img src={ErrorImg} alt="error" className="error_img" style={{ fontSize: 16 }} />
								<span className="error_text" style={{ fontWeight: 500 }}>
									There may be course changed for student
								</span>
							</>
						)
					)}
				</div>
			</div>
		</>
	);
};

export default RedeemedCard;
