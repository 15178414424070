import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { useState, useEffect } from "react";
import StudentCard from "../../student/detail/studentCard";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Swal from "sweetalert2";
import moment from "moment";
import { currencyFormatter } from "../../../utils/helperFunctions";
import AddCircle from "../../../assets/img/add_circle.svg";
import Loader from "../../common/Loader";
import DeleteConfirmation from "./installmentDelete";
import InstallmentConfirmation from "./installmentConfirmation";
import NotFound from "../../common/notFound";
import { useMediaQuery } from "@mui/material";

import "../installmentCreate.css";
import "./styles/installment-cards.css";
import { segmentEvents } from "../../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TickWhite from "../../../assets/img/tick_white.svg";

const ToastMessage = () => (
	<div
		className="reversal_toast"
		style={{
			"--toast-status": "#35CB00",
		}}
	>
		<img src={TickWhite} alt={"success"} className="mr-2" style={{ width: "1.2rem" }} />
		{"Student enrolled to course and installment created successfully."}
	</div>
);

const InstallmentEdit = (props) => {
	// let data = localStorage.getItem("userdetail");
	// data = JSON.parse(data);
	const renderResponsiveCards = useMediaQuery("(max-width: 900px)");

	let navigate = useNavigate();
	const location = useLocation();
	const { centreID, batchID, student_id } = useParams();
	const newFlow = !!(location && location.state && location.state.new_flow);
	const [showErrMsg, setShowErrMsg] = useState("hidden");
	const [errMsg, setErrMsg] = useState("");
	const [errMsgColor, setErrMsgColor] = useState("#35CB00");
	const [isDisabled, set_isDisabled] = useState(true);
	const [totalAmount, setTotalAmount] = useState(0);
	const [installmentsAmount, setInstallmentsAmount] = useState(240000);
	const [currentRowIndex, setCurrentRowIndex] = useState(null);
	const [currentRowData, setCurrentRowData] = useState(null);
	const [studentDetails, set_studentDetails] = useState(null);
	const [installments, setInstallments] = useState(
		location && location.state && location.state.installment_details && location.state.installment_details.length > 0
			? location.state.installment_details
			: []
	);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [centre, setCentre] = useState("");
	const [course, setCourse] = useState("");
	const [batch, setBatch] = useState("");
	const [loading, setLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const getStudentDetails = async () => {
		setLoading(true);

		const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_DETAILS}?student_id=${student_id}`);

		setLoading(false);
		if (data && !data.message) {
			set_studentDetails(data);
		} else if (data.message && data.message === "Student not found") {
			setNotFound(true);
		}
	};

	/**************** Get Section Subject Id **********************/
	const getFeeDetails = async () => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.COURSE_DETAIL}?school_id=${centreID}&section_id=${batchID}`);

		if (data && data.status) {
			if (data.payload.length > 0) {
				getInstalmentExist(data.payload[0].id);
				setBatch(data.payload[0].section_assoc && data.payload[0].section_assoc.section_name);
				setCourse(data.payload[0].section_assoc && data.payload[0].section_assoc.class_name);
				setCentre(data.payload[0].school_name);
			}
		} else if (data && ((data.payload && data.payload.Error) || data.message)) {
			if (data.message === "school not found" || data.message === "section not found") {
				setNotFound(true);
			} else if (data.payload && data.payload.Error) {
				Swal.fire("Error", data.payload.Error, "error");
			} else if (data.message) {
				Swal.fire("Error", data.message, "error");
			}
		}
	};
	/**************** Get Fee Details End **********************/

	const getInstalmentExist = async (id) => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_INSTALLMENT_DETAIL}${student_id}/?section_subject_id=${id}`);

		if (data && data.payload && data.payload?.data.length > 0) {
			Swal.fire("Installment Already created");
			navigate(`/student/detail/${student_id}`);
		}
	};

	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	};

	const [installmentLoading, setInstallmentLoading] = useState(false);

	const confirmInstallment = async () => {
		for (let i = 0; i < installments.length; i++) {
			if (installments[i].amount_per_installment === 0 || installments[i].amount_per_installment === "") {
				Swal.fire("Installment amount can't be 0. Please enter valid amount");
				return false;
			} else if (installments[i].start_date === "") {
				Swal.fire("Please enter valid date");
				return false;
			} else if (i > 0 && moment(installments[i].start_date).isBefore(installments[i - 1].start_date)) {
				Swal.fire("Installment date should be in chronological order");
				return false;
			} else if (i > 0 && moment(installments[i].start_date).isSame(installments[i - 1].start_date)) {
				Swal.fire("Two installments can't be of same date");
				return false;
			}
		}

		let installmentUpdate = [];
		for (let i = 0; i < installments.length; i++) {
			let instalmentOnj = {
				amount_payable: Number(installments[i].amount_per_installment),
				installment_due_date: formatDate(installments[i].start_date),
				installment_start_date: formatDate(installments[i].start_date),
				installment_no: installments[i].installment_no,
			};
			installmentUpdate.push(instalmentOnj);
		}

		let postData;
		let data = {};

		setInstallmentLoading(true);

		if (newFlow) {
			postData = {
				installment_details_list: installments.map((installment) => {
					return {
						amount_payable: installment.amount_per_installment,
						installment_due_date: formatDate(installment.end_date),
						installment_no: installment.installment_no,
						installment_start_date: formatDate(installment.start_date),
					};
				}),
			};
			data = await Api.doFetch("POST", postData, `${ApiUrl.CREATE_STUDENT_INSTALLMENTS}/${centreID}/${batchID}/${studentDetails.student_data.id}`);
		} else {
			postData = {
				section_id: batchID,
				school_id: centreID,
				no_of_installment: installments.length,
				discounted_amount: location.state.discount ? location.state.discount : 0,
				installment_details: installmentUpdate,
			};
			data = await Api.doFetch("PUT", postData, `${ApiUrl.EDIT_INSTALLMENT_PLAN}${studentDetails.student_data.id}/`);
		}

		setInstallmentLoading(false);

		if (data && data.status) {
			toast(<ToastMessage type={"success"} />);
			Api.trackEvent(segmentEvents.STUDENT.CONFIRMED_INSTALLMENTS);
			navigate(`/payment/fee-summary/${centreID}/${batchID}/${studentDetails.student_data.id}`);
		} else if (data && ((data.payload && data.payload.Error) || data.message)) {
			if (data.payload && data.payload.Error) {
				Swal.fire("Error", data.payload.Error, "error");
			} else if (data.message) {
				Swal.fire("Error", data.message, "error");
			}
		}
	};

	const matchBalance = (installmentAmount) => {
		if (totalAmount > installmentAmount) {
			setErrMsg(`Sum of installments is less than total payable amount ${currencyFormatter(totalAmount)}`);
			setShowErrMsg("inline");
			setErrMsgColor("#FF0000");
			//set_isDisabled(true);
		} else if (totalAmount < installmentAmount) {
			setErrMsg(`Sum of installments is more than total payable amount ${totalAmount}`);
			setShowErrMsg("inline");
			setErrMsgColor("#FF0000");
			//set_isDisabled(true);
		} else {
			setErrMsgColor("#35CB00");
			setShowErrMsg("hidden");
			//set_isDisabled(false);
		}
	};
	const setInputDate = (event, i) => {
		const tempRows = [...installments];
		tempRows[i].start_date = event.target.value;
		tempRows[i].end_date = event.target.value;
		setInstallments(tempRows);
	};

	const updateInputValue = (event, i) => {
		event.target.value = event.target.value.replace(/[^0-9]/g, "");

		const tempRows = [...installments];
		tempRows[i].amount_per_installment = event.target.value;
		let installmentAmount = tempRows.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);

		setIsConfirmed(false);
		set_isDisabled(true);
		matchBalance(installmentAmount);
		setInstallmentsAmount(installmentAmount);
		setInstallments(tempRows);
	};

	const addRow = () => {
		let maxObj = installments.reduce((max, obj) => (max.installment_no > obj.installment_no ? max : obj));
		let newMaxObj = JSON.parse(JSON.stringify(maxObj));

		let newDate = new Date(newMaxObj.start_date);
		let installmentData = {
			installment_no: newMaxObj.installment_no + 1,
			start_date: new Date(newDate.setMonth(newDate.getMonth() + 1)),
			end_date: new Date(newDate.setMonth(newDate.getMonth() + 1)),
			amount_per_installment: 0,
		};
		let new_installments = [...installments, installmentData];
		let installmentAmount = new_installments.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
		matchBalance(installmentAmount);
		setInstallments(new_installments);
		//matchBalance();
	};

	const addRowDel = (item, i) => {
		setCurrentRowIndex(i);
		setCurrentRowData(item);
	};

	const confirmDeleteRow = () => {
		const tempRows = [...installments];
		tempRows.splice(currentRowIndex, 1);
		let installmentAmount = tempRows.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
		let tempRowsNew = [];
		for (let i = 0; i < tempRows.length; i++) {
			tempRowsNew.push({
				amount_per_installment: tempRows[i].amount_per_installment,
				end_date: tempRows[i].end_date,
				installment_no: i + 1,
				start_date: tempRows[i].start_date,
			});
		}
		matchBalance(installmentAmount);
		setInstallmentsAmount(installmentAmount);
		//set_installments(tempRows);
		setInstallments(tempRowsNew);
		setIsConfirmed(false);
	};

	const handleConfirm = (event) => {
		setIsConfirmed(event.target.checked);
		if (event.target.checked === true) {
			if (totalAmount > installmentsAmount) {
				set_isDisabled(true);
			} else if (totalAmount < installmentsAmount) {
				set_isDisabled(true);
			} else {
				set_isDisabled(false);
			}
		} else {
			set_isDisabled(true);
		}
	};

	function convertDate(inputFormat) {
		function pad(s) {
			return s < 10 ? "0" + s : s;
		}
		var d = new Date(inputFormat);
		return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
	}

	function numberToCardinal(num) {
		var ones = num % 10;
		var tens = num % 100;
		if (tens < 11 || tens > 13) {
			switch (ones) {
				case 1:
					return num + "st";
				case 2:
					return num + "nd";
				case 3:
					return num + "rd";
				default:
					break;
			}
		}
		return num + "th";
	}

	const getStudentInstallments = async () => {
		const payload = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_INSTALLMENTS}/${centreID}/${batchID}/${student_id}`);
		if (payload.status) {
			setInstallments(payload.data);
			let installmentAmount = payload.data.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
			setInstallmentsAmount(installmentAmount);
			setTotalAmount(installmentAmount);
		}
	};

	useEffect(() => {
		if (location && location.state) {
			getStudentDetails();
			if (newFlow) getStudentInstallments();
			else {
				let installmentAmount = location.state.installment_details.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
				setInstallmentsAmount(installmentAmount);
				setTotalAmount(installmentAmount);
			}
			getFeeDetails();
		} else {
			navigate(`/student/detail/${student_id}`);
		}

		window.scrollTo(0, 0);
	}, []);

	if (notFound) {
		return <NotFound />;
	}

	return (
		<div>
			{/* Modal for delete installment */}
			<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<DeleteConfirmation
						numberToCardinal={numberToCardinal}
						current_row_data={currentRowData}
						confirmDeleteRow={confirmDeleteRow}
						convertDate={convertDate}
					/>
				</div>
			</div>

			{/* Modal for confirm installment */}
			<div
				className="modal fade installmentPlanWrap"
				id="exampleModal1"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<InstallmentConfirmation
						instalments={installments}
						numberToCardinal={numberToCardinal}
						installments_amount={installmentsAmount}
						isDisabled={isDisabled}
						confirmInstallment={confirmInstallment}
						installmentLoading={installmentLoading}
						convertDate={convertDate}
					/>
				</div>
			</div>

			<div className="containerMain">
				{loading ? (
					<div className="loader_container">
						<Loader />
					</div>
				) : (
					<>
						<StudentCard
							style={{
								marginTop: "0rem",
							}}
							studentData={studentDetails}
							goback={() => {
								if (newFlow) navigate(`/student/detail/${student_id}`);
								else navigate(`/payment/create-installment/${centreID}/${batchID}/${student_id}`);
							}}
							optionsNeeded={false}
						/>

						<div className="payment_installment_container">
							<div className={`student_course_detail-payment mb-4${renderResponsiveCards ? " responsive" : ""}`}>
								<div className="student_course_detail_centre_flex center">
									<span className="student_course_detail_header">Centre:</span>
									<p className="student_course_detail_value">{centre}</p>
								</div>
								<div className="student_course_detail_others_flex course">
									<span className="student_course_detail_header">Course:</span>
									<p className="student_course_detail_value">{course}</p>
								</div>
								<div className="student_course_detail_others_flex batch">
									<span className="student_course_detail_header">Batch:</span>
									<p className="student_course_detail_value">{batch}</p>
								</div>
							</div>

							<div>
								<div className={`installment_detail_meta_container edit-installments${renderResponsiveCards ? " responsive" : ""}`}>
									<div className="installment_detail-count">
										<p className="installment_meta_header">No. of Installments:</p>
										<p className="installment_meta_sub_header">{installments?.length}</p>
									</div>
									<div className="installment_detail-payable">
										<p className="installment_meta_header">Total Payable Fee:</p>
										<p className="installment_meta_sub_header">&#8377; {currencyFormatter(totalAmount, false)}</p>
									</div>
									<div className="installment_detail-sum">
										<p className="installment_meta_header">Sum of Installments:</p>
										<p
											className="installment_meta_sub_header_amount"
											style={{
												"--installment_total_amount": errMsgColor,
											}}
										>
											&#8377; {currencyFormatter(installmentsAmount, false)}
										</p>
									</div>
								</div>
								<div className="installment_amount_alert">
									<i className={`fa fa-exclamation-circle ${showErrMsg}`} style={{ color: "#ff0000" }}></i>
									<p className={`installment_amount_alert_text ${showErrMsg}`}>{errMsg}</p>
								</div>

								{renderResponsiveCards ? (
									<div className="installment-list-cards">
										{installments &&
											installments.map((installment, installmentIdx) => {
												return (
													<div className="installment-card" key={"installment-" + installmentIdx}>
														<div className="installment-name">
															{`${numberToCardinal(installment.installment_no)} Installment`}
														</div>
														<div className="installment-delete">
															<button
																className="addbtn2"
																data-toggle="modal"
																style={{
																	right: 0,
																}}
																data-target="#exampleModal"
																onClick={() => addRowDel(installment, installmentIdx)}
															>
																<i className="fa fa-times" aria-hidden="true"></i>
															</button>
														</div>
														<div className="installment-options">
															<div className="installment-date">
																<label htmlFor={`installment-date-${installmentIdx}`}>Date</label>
																<input
																	id={`installment-date-${installmentIdx}`}
																	type="date"
																	onKeyDown={(e) => e.preventDefault()}
																	value={dateFormat(installment.start_date, "yyyy-mm-dd")}
																	onChange={(event) => setInputDate(event, installmentIdx)}
																/>
															</div>
															<div className="installment-amount">
																<label htmlFor={`installment-amount-${installmentIdx}`}>Installment Amount</label>
																<p className="currencyWrap">
																	<span>&#8377;</span>
																	<input
																		id={`installment-amount-${installmentIdx}`}
																		className="amt"
																		type="currency"
																		value={installment.amount_per_installment}
																		onChange={(event) => updateInputValue(event, installmentIdx)}
																	/>
																</p>
															</div>
														</div>
													</div>
												);
											})}
									</div>
								) : (
									<div className="installmentList">
										<table rules="rows" className="table">
											<thead>
												<tr>
													<th className="installment_details_table_header">Installment No.</th>
													<th className="installment_details_table_header">Due Date</th>
													<th className="installment_details_table_header">Installment Amount</th>
												</tr>
											</thead>
											<tbody>
												{installments &&
													installments.map((installment, i) => {
														return (
															<tr key={"installment-" + i}>
																<td className="installment_details_table_data_text">
																	{`${numberToCardinal(installment.installment_no)} Installment`}
																</td>
																<td className="installment_details_table_data_text">
																	<input
																		type="date"
																		onKeyDown={(e) => e.preventDefault()}
																		value={dateFormat(installment.start_date, "yyyy-mm-dd")}
																		onChange={(event) => setInputDate(event, i)}
																	></input>
																</td>
																<td className="installment_details_table_data_text_amount">
																	<p className="currencyWrap">
																		<span>&#8377;</span>
																		<input
																			className="amt"
																			type="currency"
																			value={installment.amount_per_installment}
																			onChange={(event) => updateInputValue(event, i)}
																		></input>
																	</p>
																	<button
																		className="addbtn2"
																		data-toggle="modal"
																		data-target="#exampleModal"
																		onClick={() => addRowDel(installment, i)}
																	>
																		<i className="fa fa-times" aria-hidden="true"></i>
																	</button>
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
									</div>
								)}

								<div className="add_student_container add_installment_container" onClick={addRow}>
									<div className="add_student_text_container">
										<img src={AddCircle} alt="add" className="add_student_img" />

										<span className="add_student_container_text">Add Installment</span>
									</div>
								</div>

								<div className="form-check d-flex">
									<input
										className="form-check-input"
										type="checkbox"
										value=""
										checked={isConfirmed}
										id="defaultCheck1"
										onChange={(event) => handleConfirm(event)}
									/>
									<label
										className="form-check-label"
										htmlFor="defaultCheck1"
										style={{
											fontWeight: "500",
											marginLeft: "5px",
											flexGrow: 1,
											marginBottom: 0,
											marginTop: "0.3rem",
										}}
									>
										Once confirmed you can’t edit number of installments later
									</label>
								</div>
								<div className="sapceBtn">
									<button
										className="btn btn-primary btn-lg"
										data-toggle="modal"
										data-target="#exampleModal1"
										style={{
											backgroundColor: "#012C63",
											marginTop: "2%",
											fontWeight: "600",
											fontStyle: "normal",
										}}
										disabled={isDisabled}
									>
										Confirm Installment
									</button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default InstallmentEdit;
