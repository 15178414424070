import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import OtpInput from "react-otp-input";
import ErrorText from "../../common/errorText";

const OtpVerificationModal = ({ openModal, closeModal, mobile, otp, setOtp, verifyOtp, verifying, sendOtp, otpError, setOtpError }) => {
	const [timer, settimer] = useState(30);
	useEffect(() => {
		timer > 0 && setTimeout(() => settimer(timer - 1), 1000);
	}, [timer]);

	const resend = () => {
		settimer(30);
		setOtp("");
		setOtpError(null);
		sendOtp(true);
	};

	return (
		<Modal
			open={openModal}
			onClose={() => {
				closeModal();
			}}
			className="payment_reversal_cofirmation_modal"
		>
			<Box className="student_otp_modal">
				<div className="d-flex justify-content-between">
					<p className="student_otp_Heading">Student Mobile Number Verification</p>
					<p
						className="mb-0"
						style={{ cursor: "pointer", fontSize: "30px", marginTop: -10 }}
						onClick={() => {
							closeModal();
						}}
					>
						&times;
					</p>
				</div>

				<div className="d-flex" style={{ gap: 20 }}>
					<p className="student_otp_subHeading">OTP sent to student mobile</p>
					<p className="student_otp_subHeading" style={{ color: "black" }}>
						{mobile}
					</p>
				</div>

				<div style={{ height: 1, border: "1px solid #E6E6E6", marginTop: 10, marginBottom: 25 }}></div>

				<div>
					<span className="student_otp_subHeading" style={{ color: "#888C8D" }}>
						Enter 4 digit OTP
					</span>
					<OtpInput
						isInputNum
						shouldAutoFocus
						value={otp}
						onChange={(otp) => {
							console.log(otp, "errr");
							setOtp(otp);
							setOtpError(null);
						}}
						numInputs={4}
						containerStyle={"mt-3 mb-4"}
						inputStyle={{
							background: "#FFFFFF",
							border: "1px solid #858585",
							borderRadius: 6,
							width: 50,
							height: 50,
							marginRight: 15,
						}}
					/>
					{otpError && <ErrorText text={otpError} />}

					<div className="d-flex mt-2 mb-3" style={{ gap: 10 }}>
						<div className={`resent_otp ${timer === 0 && "active"}`} onClick={resend}>
							{" "}
							Resend OTP
						</div>
						{timer > 0 && <span style={{ fontSize: 16, verticalAlign: "baseline" }}>{`in (00:${timer > 9 ? timer : "0" + timer})`}</span>}
					</div>
				</div>

				<button className="btn btn-md verify_otp_button" disabled={otp.length !== 4} onClick={verifyOtp}>
					Verify OTP
					{verifying && <span className="ml-2 spinner spinner-white mr-3"></span>}
				</button>
			</Box>
		</Modal>
	);
};

export default OtpVerificationModal;
