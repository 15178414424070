import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import ArrowDown from "../../../assets/img/arrow_down.svg";
import ArrowUp from "../../../assets/img/arrow_up.svg";
import { useNavigate } from "react-router-dom";
import Accepted from "../../../assets/img/accepted.svg";
import Declined from "../../../assets/img/rejected.svg";
import { currencyFormatter } from "../../../utils/helperFunctions";
import Accordian from "../../../utils/accordian";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import moment from "moment";
import { getPaymentMode } from "../../../utils/helperFunctions";
import { useSelector } from "react-redux";
import Empty from "../../../assets/img/empty_student.svg";
import { useMediaQuery } from "@mui/material";

import "../../../styles/payment-reversal.scss";

function PaymentReversalHistory() {
	const renderResponsiveView = useMediaQuery("(max-width: 800px)");

	const navigate = useNavigate();

	const { centerList } = useSelector((state) => state.auth);

	const scrollContainer = useRef();

	const [history, setHistory] = useState([]);
	const [total, setTotal] = useState(null);

	const [loading, setLoading] = useState(true);
	const [scrollLoading, setScrollLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const limit = 10;

	const getCentres = () => {
		return centerList.map((x) => x.school_id).toString();
	};

	const getPendingReversalRequest = async (skip) => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.PAYMENT_REVERSAL}?status=2,3&school_id=${getCentres()}&skip=${skip}&limit=${limit}`);

		setLoading(false);
		setScrollLoading(false);

		if (data && data.status) {
			setTotal(data.data.total);
			setHistory([...history, ...data.data.data]);
		} else if (data && ((data && data.error_code) || data.message)) {
			if (data.error_code && data.message) {
				Swal.fire("Error", data.message, "error");
			} else if (data.message) {
				Swal.fire("Error", data.message, "error");
			}
		}
	};

	const handleScroll = async (event) => {
		const e = event.target;
		let threshold = e.scrollHeight - e.scrollTop <= e.clientHeight + 150;

		console.log(threshold);

		if (threshold && history.length < total && !scrollLoading) {
			setScrollLoading(true);
			setSkip(skip + limit);
			getPendingReversalRequest(skip + limit);
		}
	};

	const toggleDetails = (index, x) => () => {
		setHistory(
			history.map((pendingData, i) => {
				if (i === index) {
					return {
						...pendingData,
						toggle: x.toggle ? false : true,
					};
				}
				return { ...pendingData };
			})
		);
	};

	useEffect(() => {
		if (getCentres()) {
			getPendingReversalRequest(skip);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [centerList]);

	// const getTotaldue = (x) => {
	//     const totalPayable = x.installment_details && x.installment_details.total_installment_amount ? x.installment_details.total_installment_amount : 0;
	//     const totalPaid = x.installment_details && x.installment_details.paid_amount ? x.installment_details.paid_amount : 0;
	//     const reversalAmount = x.payment_assoc && x.payment_assoc && x.payment_assoc.amount_paid ? x.payment_assoc.amount_paid : 0;

	//     return ((totalPayable - totalPaid) + reversalAmount);
	// }

	return (
		<div style={{ display: "flex" }}>
			<div style={{ width: "100%", padding: "0" }}>
				{/* <div className='topHeader'>
                    <TopNavBar
                        logo={true}
                    />
                </div> */}

				<div className="containerMain">
					<div className="reversal_detail_header">
						<img
							src={LeftArrow}
							alt="go back"
							className="cursor-pointer"
							onClick={() => {
								navigate("/payment-reversal");
							}}
						/>

						<span className="reversal_detail_header_text ml-2">Reversal Payment History</span>
					</div>

					{loading ? (
						<div className="loader_container">
							<Loader />
						</div>
					) : history.length === 0 ? (
						<div
							className="student_search_empty_container"
							style={{
								marginTop: "8rem",
							}}
						>
							<div className="student_search_empty_img_container">
								<img src={Empty} alt="not found" className="student_search_empty_img" />
							</div>

							<p className="student_search_empty_header">No reversal history</p>
							<span className="student_search_empty_label">Your team is fantastic, their are no reversal</span>
							<span className="student_search_empty_label">history, we will get back to you soon</span>
						</div>
					) : (
						<div className="reversal_history_scroll_container" ref={scrollContainer} onScroll={handleScroll}>
							{history.map((x, index) => {
								if (!x.payment_assoc) return null;
								return (
									<div className={`reversal_detail_card${renderResponsiveView ? " is-mobile" : ""}`} key={index}>
										<div className="d-flex justify-content-between align-items-center">
											<div className="reversal_request_user-info">
												<div className={`student_reversal_fee_tag ${x.payment_assoc.payment_for == "advance" && "advance"}`}>
													{x.payment_assoc.payment_for == "advance" ? "Advance fee" : "Course fee"}
												</div>
												<span className="reversal_request_user">
													Raised by {x.created_by_assoc && x.created_by_assoc.first_name}
												</span>
												<span className="reversal_request_date">
													Initiated date: {moment(x.created_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
												</span>
												{+x.status === 3 ? (
													<span className="reversal_declined_tag">
														<img src={Declined} alt="declined" className="mr-1" style={{ width: "0.9rem" }} />
														Declined on {moment(x.updated_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
													</span>
												) : (
													<span className="reversal_accepted_tag">
														<img src={Accepted} alt="Accepted" className="mr-1" style={{ width: "1.1rem" }} />
														Accepted on {moment(x.updated_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
													</span>
												)}
											</div>
										</div>

										<div className="payment_reversal_table-container mt-3">
											{!renderResponsiveView ? (
												<table className="payment_reversal_table">
													<tbody>
														<tr className="payment_reversal_table_header">
															<td>Student Name</td>
															<td>Reversed Amount</td>
															<td>Paid On</td>
															<td>Ack. No.</td>
															<td>Mode</td>
														</tr>
														<tr className="payment_reversal_table_value">
															<td>{x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}</td>
															<td>{x.payment_assoc && currencyFormatter(x.payment_assoc.amount_paid)}</td>
															<td>
																{x.payment_assoc && moment(x.payment_assoc.created_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
															</td>
															<td>{x.payment_assoc && x.payment_assoc.id}</td>
															<td>{x.payment_assoc && getPaymentMode(x.payment_assoc.payment_type)}</td>
														</tr>
														<tr className="reversal_student_id">
															<td className="reversal_student_id_padding">ID: {x?.payment_assoc?.student_assoc?.id || ""}</td>
														</tr>
													</tbody>
												</table>
											) : (
												<div className="payment-reversal-card">
													<div className="payment-rc-col">
														<div className="payment-rc-header">Student Name</div>
														<div className="payment-rc-text">
															{x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}
														</div>
														<div className="reversal_student_id">ID: {x?.payment_assoc?.student_assoc?.id || ""}</div>
													</div>
													<div className="payment-rc-col">
														<div className="payment-rc-header">Amount</div>
														<div className="payment-rc-text">
															{x.payment_assoc && currencyFormatter(x.payment_assoc.amount_paid)}
														</div>
													</div>
													<div className="payment-rc-col">
														<div className="payment-rc-header">Paid On</div>
														<div className="payment-rc-text">
															{x.payment_assoc && moment(x.payment_assoc.created_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
														</div>
													</div>
													<div className="payment-rc-col">
														<div className="payment-rc-header">Ack. No.</div>
														<div className="payment-rc-text">{x.payment_assoc && x.payment_assoc.id}</div>
													</div>
													<div className="payment-rc-col">
														<div className="payment-rc-header">Mode</div>
														<div className="payment-rc-text">
															{x.payment_assoc && getPaymentMode(x.payment_assoc.payment_type)}
														</div>
													</div>
													{/* <div className="payment-rc-col">
                                                        <button
                                                            type="button"
                                                            className="payment_reversal_collapse_button"
                                                            onClick={toggleDetails(index, x)}
                                                        >
                                                            <img
                                                                src={x.toggle ? ArrowUp : ArrowDown}
                                                                alt="arrow"
                                                                style={{ width: "1.2rem" }}
                                                            />
                                                        </button>
                                                    </div> */}
												</div>
											)}
											{!renderResponsiveView && x.payment_assoc.payment_for !== "advance" && (
												<div className="payment_reversal_collapse_container">
													<button type="button" className="payment_reversal_collapse_button" onClick={toggleDetails(index, x)}>
														<img src={x.toggle ? ArrowUp : ArrowDown} alt="arrow" style={{ width: "1.2rem" }} />
													</button>
												</div>
											)}
										</div>

										{renderResponsiveView ? (
											<div className="row mx-0 mt-4">
												<div className="col-xs-10 px-0">
													<div className="payment-reversal-header">Reason For Reversal</div>
													<p className="reversal_reason_text">{x.counsellor_remarks}</p>
												</div>
												{x.payment_assoc.payment_for !== "advance" && (
													<div className="col-xs-2 px-0">
														<div className="text-right">
															<button
																type="button"
																className="payment_reversal_collapse_button"
																onClick={toggleDetails(index, x)}
															>
																<img src={x.toggle ? ArrowUp : ArrowDown} alt="arrow" style={{ width: "1.2rem" }} />
															</button>
														</div>
													</div>
												)}
											</div>
										) : (
											<div className="mt-4">
												<div className="reversal_request_date">Reason For Reversal</div>

												<p className="reversal_reason_text">{x.counsellor_remarks}</p>
											</div>
										)}

										<Accordian expand={!!x.toggle}>
											<hr />

											{+x.status === 3 && (
												<div className="">
													{renderResponsiveView ? (
														<div className="payment-reversal-header">Reason to Decline</div>
													) : (
														<div className="reversal_request_date">Reason to Decline</div>
													)}

													<p className="reversal_reason_text">{x.updated_remarks}</p>
												</div>
											)}
											<div className="mt-4">
												{renderResponsiveView ? (
													<div className="payment-reversal-header">Installment Information</div>
												) : (
													<div className="reversal_request_date">Installment Information</div>
												)}

												<div className="d-flex reversal_detail_2">
													{!renderResponsiveView ? (
														<table className="payment_reversal_table">
															<tbody>
																<tr className="payment_reversal_table_header">
																	<td className="">No. of Installments</td>
																	<td>Total Payable</td>
																	<td>Total Paid</td>
																	<td></td>
																</tr>
																<tr className="payment_reversal_table_value">
																	<td>
																		{x.installment_details && x.installment_details.installment_no
																			? x.installment_details.installment_no
																			: 0}
																	</td>
																	<td>
																		{currencyFormatter(
																			x.installment_details && x.installment_details.total_installment_amount
																				? x.installment_details.total_installment_amount
																				: 0
																		)}
																	</td>
																	<td>
																		{currencyFormatter(
																			x.installment_details && x.installment_details.paid_amount
																				? x.installment_details.paid_amount
																				: 0
																		)}
																	</td>
																	<td></td>
																</tr>
															</tbody>
														</table>
													) : (
														<div className="payment-reversal-card spaced">
															<div className="payment-rc-col">
																<div className="payment-rc-header">No. of Installments</div>
																<div className="payment-rc-text">
																	{x.installment_details && x.installment_details.installment_no
																		? x.installment_details.installment_no
																		: 0}
																</div>
															</div>
															<div className="payment-rc-col">
																<div className="payment-rc-header">Total Payable</div>
																<div className="payment-rc-text">
																	{currencyFormatter(
																		x.installment_details && x.installment_details.total_installment_amount
																			? x.installment_details.total_installment_amount
																			: 0
																	)}
																</div>
															</div>
															<div className="payment-rc-col">
																<div className="payment-rc-header">Total Paid</div>
																<div className="payment-rc-text">
																	{currencyFormatter(
																		x.installment_details && x.installment_details.paid_amount
																			? x.installment_details.paid_amount
																			: 0
																	)}
																</div>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className="mt-4 reversal_detail">
												{renderResponsiveView ? (
													<div className="payment-reversal-header">Student Information</div>
												) : (
													<div className="reversal_request_date">Student Information</div>
												)}

												<div className="d-flex reversal_detail">
													{renderResponsiveView ? (
														<div className="payment-reversal-card even">
															<div className="payment-rc-col">
																<div className="payment-rc-header">Student Name</div>
																<div className="payment-rc-text">
																	{x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}
																</div>
															</div>
															<div className="payment-rc-col">
																<div className="payment-rc-header">Mobile Number</div>
																<div className="payment-rc-text">
																	{x.payment_assoc.student_assoc &&
																		`*****${x.payment_assoc.student_assoc.mobile_number.substring("5")}`}
																</div>
															</div>
															<div className="payment-rc-col">
																<div className="payment-rc-header">Centre</div>
																<div className="payment-rc-text">
																	{x.payment_assoc.section_assoc &&
																		x.payment_assoc.section_assoc.school_assoc &&
																		x.payment_assoc.section_assoc.school_assoc.name}
																</div>
															</div>
															<div className="payment-rc-col">
																<div className="payment-rc-header">Course</div>
																<div className="payment-rc-text">
																	{x.payment_assoc.section_assoc &&
																		x.payment_assoc.section_assoc.section_assoc &&
																		x.payment_assoc.section_assoc.section_assoc.class_assoc &&
																		x.payment_assoc.section_assoc.section_assoc.class_assoc.class_name}
																</div>
															</div>
														</div>
													) : (
														<table className="payment_reversal_table">
															<tbody>
																<tr className="payment_reversal_table_header">
																	<td>Student Name</td>
																	<td>Mobile Number</td>
																	<td>Centre</td>
																	<td>Course</td>
																</tr>
																<tr className="payment_reversal_table_value">
																	<td>
																		{x.payment_assoc &&
																			x.payment_assoc.student_assoc &&
																			x.payment_assoc.student_assoc.name}
																	</td>
																	<td>
																		{x.payment_assoc &&
																			x.payment_assoc.student_assoc &&
																			`*****${x.payment_assoc.student_assoc.mobile_number.substring("5")}`}
																	</td>
																	<td>
																		{x.payment_assoc &&
																			x.payment_assoc.section_assoc &&
																			x.payment_assoc.section_assoc.school_assoc &&
																			x.payment_assoc.section_assoc.school_assoc.name}
																	</td>
																	<td>
																		{x.payment_assoc &&
																			x.payment_assoc.section_assoc &&
																			x.payment_assoc.section_assoc.section_assoc &&
																			x.payment_assoc.section_assoc.section_assoc.class_assoc &&
																			x.payment_assoc.section_assoc.section_assoc.class_assoc.class_name}
																	</td>
																</tr>
															</tbody>
														</table>
													)}
												</div>
											</div>
										</Accordian>
									</div>
								);
							})}
							{scrollLoading && (
								<div className="loader_scroll_container">
									<Loader />
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default PaymentReversalHistory;
