import React ,{useEffect, useState} from "react";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import MywalletTab from "../../student/editStudent/mywalletTab";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Swal from "sweetalert2";

const MyWalletHistory = () => {
	const navigate = useNavigate();
	const { studentID } = useParams();
    const [loading, setLoading] = useState(true)
    const [walletData, setWalletData] = useState(null)

	React.useEffect(() => {
		console.log(studentID, "student ID");
        // alert(studentID)?
	}, [studentID]);

const walletPaymentHistory = async () => {
	const data = await Api.doFetch("GET", {}, `${ApiUrl.WALLET_HISTORY}${studentID}`);
	setLoading(false);
	if (data && data.status) {
		console.log(data, "index");
		setWalletData(data.data);
	} else {
		if (data && data.message) {
			Swal.fire("Error!", data.message, "error");
		} else {
			Swal.fire("Error!", "Internal Server Error", "error");
		}
	}
};

useEffect(() => {
	walletPaymentHistory();
}, []);

	return (
		<div className="course_list_container">
			<div className="course_list_header">
				<div className="d-flex">
					<div className="pending-cheques__heading_text my-1">
						<img
							src={LeftArrow}
							alt="goback"
							className="cursor-pointer mr-2"
							onClick={() => {
								navigate(-1);
							}}
						/>
						Mywallet history
					</div>
				</div>
			</div>

			<ul className="nav nav-tabs" style={{ marginTop: 20, display: "flex", flexWrap: "wrap" }}>
				<li className="active">
					<a className={`fee_summary_tab fee_summary_tab_selected`}>
						<span style={{ color: "#F47B26", fontWeight: "600" }}>my</span>
						<span style={{ color: "#1F3061", fontWeight: "600" }}>wallet</span>
					</a>
				</li>
			</ul>

			<div className="tab-content">
				<div
					className="tab-pane fade in active"
					style={{
						backgroundColor: "#FFFFFF",
						border: "none",
					}}
				>
					<MywalletTab walletData={walletData} loading={loading} refreshData={walletPaymentHistory} />
				</div>
			</div>
		</div>
	);
};

export default MyWalletHistory;
