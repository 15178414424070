import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Asterisk } from "../../../utils/helperFunctions";
import { roleConstant } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Error from "../../common/errorText";

const AdvancePaymentModal = ({ leadID, hasCentre, advancePaymentModalOpen, closeAdvancePaymentModal }) => {
	const { roleList } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const [paymentMethod, setPaymentMethod] = React.useState("cash");
	const [amount, setAmount] = React.useState("");

	const closeModal = () => {
		closeAdvancePaymentModal();
		setError("");
		setAmount("");
	};

	const style = {
		position: "absolute",
		top: "40%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: 24,
		maxWidth: 700,
		width: "90%",
		padding: "3rem",
		borderRadius: 2,
	};
	const [isStudentManager, setIsStudentManager] = useState(false);
	const [error, setError] = useState("");

	useEffect(() => {
		if (roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
			setIsStudentManager(true);
		}
	}, [roleList]);

	const goToPayment = () => {
		if (paymentMethod === "razorpay") {
			Swal.fire("Coming Soon!");
			return false;
		}
		if (amount > 0) {
			navigate(`/lead/advance-payment/${leadID}`, {
				state: {
					paymentAmount: amount,
					paymentMethod: paymentMethod,
				},
			});
		} else {
			Swal.fire("Amount is blank! Enter valid amount.");
			return false;
		}
	};

	return (
		<>
			<Modal open={advancePaymentModalOpen} onClose={closeModal} className="payment_reversal_cofirmation_modal">
				<Box sx={style}>
					<div className="d-flex justify-content-between align-items-center">
						<p className="reversal_accept_text">Collect advance</p>
						<p className="mb-0" style={{ cursor: "pointer", fontSize: "30px" }} onClick={closeModal}>
							&times;
						</p>
					</div>

					<div className="row mt-4 ">
						<div className="col-sm-12">
							<div className="form-group">
								<label htmlFor="text">Enter Amount</label> <Asterisk />
								<div className="input-group discountWrpField col-sm-6 col-md-4 col-lg-3" style={{ width: "40%" }}>
									<span className="input-group-addon">&#8377;</span>
									<input
										value={amount}
										style={{ width: "100%" }}
										type="text"
										placeholder="Enter Amount"
										className="form-control discountWrp"
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
												event.preventDefault();
											}
										}}
										onChange={(event) => {
											setError("");
											setAmount(event.target.value);
										}}
										autoComplete="off"
									/>
								</div>
								{error && <Error text={error} />}
							</div>
							<div className="paymentPopup">
								<div className="form-group">
									<label htmlFor="pwd">Mode of payment:</label>
									<div className="">
										<label className={`cash contain ${paymentMethod === "cash" && " active"}`}>
											Cash
											<input
												type="radio"
												name="paymentMethodHostel"
												checked={paymentMethod === "cash"}
												onChange={(event) => {
													if (event.target.checked) {
														setPaymentMethod("cash");
													}
												}}
											/>
											<span className="checkmark"></span>
										</label>
										<label className={`cash contain ${paymentMethod === "cheque" && " active"}`}>
											Cheque
											<input
												type="radio"
												name="paymentMethodHostel"
												checked={paymentMethod === "cheque"}
												onChange={(event) => {
													if (event.target.checked) {
														setPaymentMethod("cheque");
													}
												}}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
								<div className="form-group">
									<label
										className={`cash contain ${paymentMethod === "icici" && "active"} ${!hasCentre && "disabled"}`}
										style={!hasCentre ? { opacity: "0.4" } : {}}
									>
										new POS (ICICI)
										<input
											disabled={!hasCentre}
											type="radio"
											name="paymentMethodHostel"
											checked={paymentMethod === "icici"}
											onChange={(event) => {
												if (event.target.checked) {
													setPaymentMethod("icici");
												}
											}}
										/>
										<span className="checkmark"></span>
									</label>
									<label className={`cash contain ${paymentMethod === "link" && " active"}`}>
										Share Payment Link
										<input
											type="radio"
											name="paymentMethodHostel"
											checked={paymentMethod === "link"}
											onChange={(event) => {
												if (event.target.checked) {
													setPaymentMethod("link");
												}
											}}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
								{/* <div className="form-group">
									<label className={`cash contain ${paymentMethod === "razorpay" && " active"}`}>
										Online Payment (Razor Pay)
										<input
											type="radio"
											name="paymentMethodHostel"
											checked={paymentMethod === "razorpay"}
											onChange={(event) => {
												if (event.target.checked) {
													setPaymentMethod("razorpay");
												}
											}}
										/>
										<span className="checkmark"></span>
									</label>
								</div> */}
								<div className="form-group">
									{isStudentManager && (
										<label className={`cash contain ${paymentMethod === "bankdeposit" && " active"}`}>
											Bank Deposit
											<input
												type="radio"
												name="radio"
												checked={paymentMethod === "bankdeposit"}
												value="bankdeposit"
												onChange={(event) => {
													if (event.target.checked) {
														setPaymentMethod("bankdeposit");
													}
												}}
											/>
											<span className="checkmark"></span>
										</label>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="mt-3" style={{ float: "left" }}>
						<button
							className="btn btn-md student_detail_password_confirm"
							onClick={() => {
								if (+amount > 0) {
									closeModal();
									goToPayment();
									setError("");
								} else {
									setError("Please enter valid Amount");
								}
							}}
						>
							Next
						</button>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default AdvancePaymentModal;
