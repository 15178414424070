import React from "react";
import { useMediaQuery } from "@mui/material";

import incomingIcon from "../../../assets/img/incoming.svg";
import outgoingIcon from "../../../assets/img/outgoing.svg";
import ReceiptImg from "../../../assets/img/receipt.svg";
import ReversalImg from "../../../assets/img/reversal.svg";
import Flag from "../../../assets/img/flag.svg";

import moment from "moment";
import { currencyFormatter, getPaymentMode } from "../../../utils/helperFunctions";

import { MoreVert } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import Reversal from "../../payment/feeSummary/reversal";
import { courseTypeList } from "../../../utils/helperFunctions";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const WalletHistoryCard = ({ item, refreshData, centreChanged, courseChanged }) => {
	const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
		({ theme }) => ({
			[`& .${tooltipClasses.arrow}`]: {
				color: theme.palette.common.black,
			},
			[`& .${tooltipClasses.tooltip}`]: {
				backgroundColor: theme.palette.common.black,
				fontSize: "13px",
			},
		})
	);

	const isMobile = useMediaQuery("(max-width: 850px)");
	const [reversalID, setReversalID] = React.useState("");
	const [courseHistoryLoading, setCourseHistoryLoading] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [reversalDeclined, setReversalDeclined] = React.useState("");

	const handleToggleReversalReason = (paymentHistoryId) => () => {
		if (reversalDeclined !== paymentHistoryId) {
			setReversalDeclined(paymentHistoryId);
		} else {
			setReversalDeclined("");
		}
	};

	const onMenuClick = (e) => {
		if (anchorEl) {
			setAnchorEl(null);
			return;
		}
		setAnchorEl(e.currentTarget);
	};

	const canViewReceipt = (status, payment_type) => {
		return status === "Paid" ||
			((payment_type === "cheque" || payment_type === "Cheque") && (status === "Paid" || status === "Pending")) ||
			(payment_type === "Bank deposit" && (status === "Paid" || status === "Pending")) ||
			(payment_type === "No cost EMI" && (status === "Paid" || status === "Pending"))
			? true
			: false;
	};

	const downloadReceipt = async (paymentId) => {
		window.open(`/payment/paymentReceiptSummary/${paymentId}`, "_blank");
		// let formdata = new FormData();
		// formdata.append("student_id", student_id);
		// formdata.append("payment_id", paymentId);
		// formdata.append("section_id", studentDetails.student_data.section_assoc.id);
		// formdata.append("school_id", studentDetails.student_data.school_assoc.id);
		// set_downloaodClicked(true);
		// set_downloadPaymentId(paymentId);
		// const paymentHistoryRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/generate-payment-receipt/`, formdata);
		// if (paymentHistoryRes.status===200) {
		//   window.open(paymentHistoryRes.data.payload.invoice_url, "_blank");
		//   set_downloaodClicked(false);
		//   set_downloadPaymentId();
		// } else {
		//   alert("Something went wrong, please try later");
		//   set_downloaodClicked(false);
		//   set_downloadPaymentId();
		// }
	};
	const checkCashConfirmed = (cash_confirmed) => {
		return cash_confirmed ? true : false;
	};

	const canDoReversal = (payment_type, reversalPending, status) => {
		if (reversalPending) {
			return false;
		}
		if (status !== "Paid") {
			return false;
		}
		switch (payment_type) {
			case "cash":
			case "Cash":
			case "cheque":
			case "Cheque":
			case "Bank Transfer":
			case "swipe":
			case "Swipe":
			case "Bank deposit":
			case "No cost EMI":
				return true;
			case "CommerceConnect":
			case "commerceconnect":
			case "razorpay":
			case "Razorpay":
			case "paytm":
			case "Paytm":
			case "ezetap":
			case "link":
				return false;
			default:
				return false;
		}
	};

	return (
		<div className="card_wallet_container">
			<div className="course_list_card my-0">
				<div className="course_divider1 myWalletDiv">
					<img src={item?.payment_for === "advance" ? incomingIcon : outgoingIcon} alt="" />
					<div>
						<div className="course_list_card_value" style={{ fontSize: "unset" }}>
							{item?.payment_for === "advance" ? (
								"Advance added to wallet"
							) : item?.student_batch.length > 20 ? (
								<BootstrapTooltip disableFocusListener enterTouchDelay={0} title={item?.student_batch}>
									<p>{"Redeemed for " + item?.student_batch.substring(0, 20) + "..."}</p>
								</BootstrapTooltip>
							) : (
								<p>{"Redeemed for " + item?.student_batch}</p>
							)}
						</div>

						<div className="smallTextWallet">Added by: {item?.addedBy}</div>

						<div className="smallTextWallet">Date: {moment(item?.created_on).format("DD MMM, YYYY") || "-"}</div>
					</div>
				</div>
				<div className="course_divider2 big">
					<div className="course_list_card_value" style={{ fontSize: "unset" }}>
						{item?.ack_no}
					</div>
					<div className="smallTextWallet">Mode: {getPaymentMode(item?.payment_type)}</div>
				</div>
				<div className="course_divider1">
					<div className={`course_list_card_value`} style={{ fontSize: "unset", color: centreChanged ? "red" : "black" }}>
						{item?.student_centre ? item?.student_centre : "-"}
					</div>
				</div>
				<div className="course_divider2 big">
					<div className="course_list_card_value" style={{ fontSize: "unset", color: courseChanged ? "red" : "black" }}>
						{courseTypeList[item?.student_course_type] || item?.student_course_type || "-"}
					</div>
				</div>
				<div className="course_divider2 big">
					<div className="course_list_card_value" style={{ fontSize: "unset" }}>
						{item?.student_state ? item?.student_state : "-"}
					</div>
				</div>
				<div className="course_divider2">
					<div className="course_list_card_value" style={{ fontSize: "unset" }}>
						{item?.payment_for !== "advance" ? "- " : "+ "}
						{currencyFormatter(item?.amount_paid)}
					</div>
				</div>
				<div className="course_divider2" style={{ minWidth: 120 }}>
					<div className="course_list_card_value d-flex align-items-center" style={{ fontSize: "unset" }}>
						{item?.status === "Paid" && <div className="successTagWallet">Success</div>}
						{(item?.status === "Failure" || item?.status === "Failed") && <div className="failedTagWallet">Failed</div>}
						{item?.status === "Pending" && <div className="pendingTagWallet">Pending</div>}
						{item?.status === "Reversed" && <div className="reversedTagWallet">Reversed</div>}
						{item?.is_reversal_refund_init && <img src={Flag} alt="flag" className="reversal_raised_flag_wallet" />}
						{item?.payment_type === "mywallet"
							? null
							: (canViewReceipt(item?.status, item?.payment_type) || item?.status === "Paid") && (
									<div className="d-flex align-items-center ml-3 cursor-pointer" onClick={(e) => onMenuClick(e)}>
										<MoreVert fontSize="large" />
										<Menu
											id="long-menu"
											anchorEl={anchorEl}
											keepMounted
											open={anchorEl}
											onClose={onMenuClick}
											PaperProps={{
												style: {
													boxShadow: "10px 14px 20px rgba(0, 0, 0, 0.12)",
												},
											}}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
											style={{
												marginLeft: "1rem",
												marginTop: "-0.8rem",
											}}
										>
											{
												<MenuItem
													onClick={() => {
														(canViewReceipt(item?.status, item?.payment_type) || item?.status === "Paid") &&
															downloadReceipt(item?.id);
													}}
													style={{
														padding: "1rem 2rem",
													}}
													className="mui_dropdown_options"
												>
													<img src={ReceiptImg} alt="img" style={{ width: "1.7rem" }} />
													<span className="payment_history_options_text">View receipt</span>
												</MenuItem>
											}

											{canDoReversal(item?.payment_type, item.is_reversal_refund_init, item?.status) && (
												<MenuItem
													style={{
														padding: "1rem 2rem",
													}}
													className="mui_dropdown_options"
													onClick={() => {
														if (checkCashConfirmed(item?.cash_confirmed)) {
															Swal.fire(
																"",
																`Cash entry already confirmed by ${item?.accountant_name} (Accountant), can’t be reversed`,
																"warning"
															);
														} else if (item?.is_redeemed) {
															Swal.fire(
																"",
																`Wallet payment is already redeemed for this transaction, can’t be reversed`,
																"warning"
															);
														} else if (canDoReversal(item?.payment_type, item?.is_reversal_refund_init, item?.status)) {
															// setReversalInit(true);
															setReversalID(item?.id);
														} else {
															Swal.fire(
																"",
																"Reversal can only be initiated where mode of payment is Cash/Cheque/offline swipe POS/Bank Transfer (UPI)",
																"warning"
															);
														}
													}}
												>
													<img src={ReversalImg} alt="img" style={{ width: "1.7rem" }} />
													<span className="payment_history_options_text">Initiate reversal</span>
												</MenuItem>
											)}
										</Menu>
									</div>
							  )}
					</div>
				</div>
			</div>

			{item?.id === reversalID ? (
				<div style={{ maxWidth: 500 }}>
					<Reversal
						isLead={true}
						reversalID={reversalID}
						refresh={(reload) => {
							setReversalID("");
							if (reload) {
								setCourseHistoryLoading(true);
								refreshData();
							}
						}}
					/>
				</div>
			) : item?.is_reversal_refund_init ? (
				<tr>
					<td colSpan={"6"} className="reversal_td_container">
						<div className="reversal_reason_container_Lead small">
							<img src={Flag} alt="flag" className="reversal_raised_flag mr-1" />
							<span className="reversal_raised_text">Reversal initiated for the payment, waiting on Centre Head for approval.</span>
						</div>
					</td>
				</tr>
			) : item.reversal_declined ? (
				<tr>
					<td colSpan={"6"} className="reversal_td_container">
						<div className="reversal_reason_container_flag_Lead">
							<div className="reversal_raised_text reversal_declined">
								<div className={`reversal_declined_content${reversalDeclined === item.id ? " open" : ""}`}>
									<div className="reversal_declined_tag">Reason to decline</div>

									<div className="reversal_declined_remark">{item.reversal_declined_remarks}</div>

									<div className="reversal_declined_centre_head">Declined by {item.reversal_declined_by} (Centre Head)</div>
								</div>

								<span className="reversal_declined_toggle" onClick={handleToggleReversalReason(item.id)}>
									{reversalDeclined === item.id ? (
										"Hide reason"
									) : (
										<>
											<span style={{ color: "#AF0000" }}>Reversal request declined, </span>
											<span>click to know reason</span>
										</>
									)}
								</span>
							</div>
						</div>
					</td>
				</tr>
			) : null}
		</div>
	);
};

export default WalletHistoryCard;
