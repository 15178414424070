import React from "react";
import ListImage from "../../assets/img/list-att-icon.svg";
import HubIcon from "../../assets/img/hubIcon.svg"
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SCRL_RESET_LIST } from "../../actions/types";


const Hub= ()=> {

    let navigate = useNavigate();

    const dispatch = useDispatch();

    return (
        <div className="containerMain">

            <div className="payment_reversal_container">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/hub/add") 
                    }}
                >

                    <img
                        src={HubIcon}
                        alt="add course"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Add hub
                    </span>

                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        dispatch({ type: SCRL_RESET_LIST });
                        navigate("/hub/list");
                    }}
                >

                    <img
                        src={ListImage}
                        alt="subject list"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of hubs
                    </span>

                </div>

            </div>

        </div>
    );
}
export default Hub;
